import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC } from 'react';
import { ApolloWrapper } from '~/contexts/ApolloWrapper';
import { AuthProvider } from '~/contexts/AuthContext';

const queryClient = new QueryClient();

export const AppWrapper: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <ApolloWrapper>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </AuthProvider>
    </ApolloWrapper>
  );
};
