import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OtpCode } from '~/components/OtpCode/OtpCode';
import { useSendOtpMutation, useVerifyOtpMutation } from '~/graphql/generated';
import { IRegisterFormData } from './Register';
import { checkWebAuthnSupport } from '~/utils/webauthn-support';
import { getOtpTiming, isInCooldown, removeOtpTiming, setOtpTiming } from '~/utils/otp';

interface LocationState {
  phone: string;
  registrationData?: IRegisterFormData;
}

export default function VerifyOTP() {
  const location = useLocation();
  const navigate = useNavigate();
  const [verifyOTPMutation] = useVerifyOtpMutation();
  const [sendOTPMutation] = useSendOtpMutation();

  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const webAuthnSupport = checkWebAuthnSupport();

  // Get the initial state
  const { registrationData, phone } = location.state as LocationState;

  // Keep OTP data in local state for updates
  const [currentOtpData, setCurrentOtpData] = useState<
    | {
        preAuthSessionId: string;
        deviceId: string;
      }
    | undefined
  >();

  const handleVerification = async (code: string) => {
    if (!currentOtpData) {
      setError('Molimo zatražite verifikacijski kod');
      return;
    }

    setIsLoading(true);
    setError(undefined);

    try {
      const result = await verifyOTPMutation({
        variables: {
          input: {
            phoneNumber: phone,
            preAuthSessionId: currentOtpData.preAuthSessionId,
            deviceId: currentOtpData.deviceId,
            userInputCode: code,
            name: registrationData?.name,
          },
        },
      });

      const verifyResponse = result.data?.verifyOTP;

      if (verifyResponse?.success) {
        removeOtpTiming(phone);

        // Create session with SuperTokens
        try {
          // if device support webauthn, navigate to setup webauthn
          if (webAuthnSupport.isSupported) {
            navigate('/setup-webauthn');
          } else {
            // Navigate to next step after successful session creation
            navigate('/');
          }
        } catch (sessionError) {
          setError('Pogreška pri stvaranju sesije. Molimo pokušajte ponovno.');
          return;
        }
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Verifikacija nije uspjela');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = () => handleSendOTP();

  // Handle initial OTP send
  const handleSendOTP = async () => {
    if (isResending || isLoading) return;
    // Check if in cooldown period
    if (isInCooldown(phone)) {
      const timingData = getOtpTiming(phone);
      setCurrentOtpData(timingData?.otpData ? timingData?.otpData : currentOtpData);
      return;
    }

    setIsResending(true);
    setError(undefined);

    try {
      setOtpTiming(phone);
      const result = await sendOTPMutation({
        variables: {
          input: {
            phoneNumber: phone,
            isRegistration: Boolean(registrationData),
          },
        },
      });

      if (!result.data) {
        throw new Error('Greška pri slanju verifikacijskog koda');
      }

      const newOtpData = {
        preAuthSessionId: result.data.sendOTP.preAuthSessionId,
        deviceId: result.data.sendOTP.deviceId,
      };

      setCurrentOtpData(newOtpData);
      // Update timing data with OTP data
      setOtpTiming(phone, newOtpData);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Greška pri slanju verifikacijskog koda');
    } finally {
      setIsResending(false);
    }
  };

  // Send initial OTP when component mounts
  useEffect(() => {
    if (!currentOtpData) {
      handleSendOTP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={3}>
      <Typography variant="h6" align="center">
        Verificirajte svoj broj telefona
      </Typography>

      <OtpCode
        onSubmit={handleVerification}
        onResend={handleResend}
        phone={phone}
        error={error}
        loading={isLoading || isResending}
        timingData={getOtpTiming(phone)}
      />
    </Stack>
  );
}
