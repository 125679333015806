export interface OtpTimingData {
  timestamp: number;
  otpData?: {
    preAuthSessionId: string;
    deviceId: string;
  };
}

const COOLDOWN_PERIOD = 60 * 1000; // 60 seconds in milliseconds

const otpTimingKey = (phone: string) => `otpTiming_${phone}`;

export const getOtpTiming = (phone: string): OtpTimingData | null => {
  const data = localStorage.getItem(otpTimingKey(phone));
  return data ? JSON.parse(data) : null;
};

export const setOtpTiming = (phone: string, otpData?: OtpTimingData['otpData']) => {
  const timingData: OtpTimingData = {
    timestamp: Date.now(),
    otpData,
  };

  localStorage.setItem(otpTimingKey(phone), JSON.stringify(timingData));
};

export const removeOtpTiming = (phone: string) => {
  localStorage.removeItem(otpTimingKey(phone));
};

export const isInCooldown = (phone: string): boolean => {
  const timing = getOtpTiming(phone);
  if (!timing) return false;

  const elapsed = Date.now() - timing.timestamp;
  const isInCooldown = elapsed < COOLDOWN_PERIOD;
  if (!isInCooldown) {
    removeOtpTiming(phone);
  }
  return isInCooldown;
};
