import { Container, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useContext, useState } from 'react';
import { AppButton } from '~/components/AppButton';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { AppInput } from '~/components/AppInput/AppInput';
import { AppLabel } from '~/components/AppLabel/AppLabel';
import { AuthContext } from '~/contexts/authContextCore';
import { useUpdateProfileMutation } from '~/graphql/generated';

export const Profile: FC = () => {
  const [updateProfileMutation, { loading: updateProfileLoading }] = useUpdateProfileMutation({
    onCompleted: () => {
      enqueueSnackbar('Uspješno spremljeno', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 3000,
      });
    },
  });
  const { userData } = useContext(AuthContext);
  const [name, setName] = useState(userData?.name || '');

  const updateName = () => {
    updateProfileMutation({
      variables: {
        data: {
          name,
        },
      },
    });
  };

  return (
    <>
      <AppHeader />
      <AppLabel text="Uredi profil" />
      <Container
        sx={{
          flex: 1,
        }}
      >
        <Stack justifyContent={'space-between'} gap={1.25} width={'100%'} height={'100%'}>
          <Stack width={'100%'} gap={1.5}>
            <AppInput
              label="Ime i prezime"
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
          </Stack>
          <AppButton
            disabled={!name || name === userData?.name}
            variant="contained"
            size="large"
            fullWidth
            onClick={updateName}
            loading={updateProfileLoading}
          >
            Spremi
          </AppButton>
        </Stack>
      </Container>
    </>
  );
};
