import { browserSupportsWebAuthn } from '@simplewebauthn/browser';

export interface WebAuthnSupportStatus {
  isSupported: boolean;
  browserSupport: boolean;
  secureContext: boolean;
}

export function checkWebAuthnSupport(): WebAuthnSupportStatus {
  const isSecureContext = window.isSecureContext;
  const hasBrowserSupport = browserSupportsWebAuthn();

  return {
    isSupported: isSecureContext && hasBrowserSupport,
    browserSupport: hasBrowserSupport,
    secureContext: isSecureContext,
  };
}
