import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthContext } from '~/contexts/authContextCore';

export const AuthenticationContainer = () => {
  const navigate = useNavigate();

  const context = useContext(AuthContext);

  useEffect(() => {
    if (!context.mounting) {
      if (context.isLoggedIn) {
        navigate('/', {
          replace: true,
        });
      }
    }
  }, [context, navigate]);

  return (
    <>
      <Outlet />
    </>
  );
};
