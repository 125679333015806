import { Navigate, createBrowserRouter } from 'react-router-dom';
import { AuthenticatedContainer } from '~/components/AuthenticaticatedContainer';
import { AuthenticationChildrenContainer } from '~/components/AuthenticationChildrenContainer';
import { AuthenticationContainer } from '~/components/AuthenticationContainer';
import { AppointmentReview } from '~/screens/Appointments/AppointmentReview';
import { Appointments } from '~/screens/Appointments/Appointments';
import { AutheticationOptions } from '~/screens/Authentication/AutheticationOptions';
import Login from '~/screens/Authentication/Login';
import Register from '~/screens/Authentication/Register';
import { ServiceSelection } from '~/screens/Booking/ServiceSelection';
import { Home } from '~/screens/Home';
import { EditPhone } from '~/screens/Settings/Phone/EditPhone';
import { PhoneVerification } from '~/screens/Settings/Phone/PhoneVerification';

import { ErrorBoundaryLayout } from '~/components/ErrorFallback/ErrorFallback';
import { AppWrapper } from '~/contexts/AppWrapper';
import TermsAndConditions from '~/screens/Authentication/TermsAndConditions';
import VerifyOTP from '~/screens/Authentication/VerifyOTP';
import { BarberAndTimeSelection } from '~/screens/Booking/BarberAndTimeSelection';
import { BookingContextProvider } from '~/screens/Booking/BookingContextProvider';
import { BookingReview } from '~/screens/Booking/BookingReview';
import { NotBookableService } from '~/screens/Booking/NotBookableService';
import { EditFavouriteBarber } from '~/screens/Settings/EditFavouriteBarber';
import { EditFavouriteService } from '~/screens/Settings/EditFavouriteService';
import { EditNotification } from '~/screens/Settings/EditNotification';
import { Profile } from '~/screens/Settings/Profile';
import { Settings } from '~/screens/Settings/Settings';
import SetupWebAuthn from '~/screens/Authentication/SetupWebAuthn';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';

export const router = createBrowserRouter([
  {
    element: (
      <AppWrapper>
        <ErrorBoundaryLayout />
      </AppWrapper>
    ),
    children: [
      {
        path: 'setup-webauthn',
        element: (
          <SessionAuth requireAuth={true}>
            <SetupWebAuthn />
          </SessionAuth>
        ),
      },
      {
        path: '/',
        element: <AuthenticatedContainer />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: 'appointments',
            children: [
              {
                index: true,
                element: <Appointments />,
              },
              {
                path: ':id',
                element: <AppointmentReview />,
              },
            ],
          },
          {
            path: 'booking',
            Component: BookingContextProvider,
            children: [
              {
                index: true,
                element: <ServiceSelection />,
              },
              {
                path: 'barber-time',
                element: <BarberAndTimeSelection />,
              },
              {
                path: 'not-bookable',
                element: <NotBookableService />,
              },
              {
                path: 'review',
                element: <BookingReview />,
              },
            ],
          },
          {
            path: 'settings',
            children: [
              {
                index: true,
                element: <Settings />,
              },
              {
                path: 'profile',
                element: <Profile />,
              },
              {
                path: 'phone',
                children: [
                  {
                    index: true,
                    element: <EditPhone />,
                  },
                  {
                    path: 'verification',
                    element: <PhoneVerification />,
                  },
                ],
              },
              {
                path: 'notification',
                element: <EditNotification />,
              },
              {
                path: 'favourite/service',
                element: <EditFavouriteService />,
              },
              {
                path: 'favourite/barber',
                element: <EditFavouriteBarber />,
              },
            ],
          },
        ],
      },
      {
        path: 'authentication',
        Component: AuthenticationContainer,
        children: [
          {
            index: true,
            element: <Navigate to="options" replace />,
          },
          {
            path: 'options',
            Component: AutheticationOptions,
          },
          {
            path: 'terms-and-conditions',
            Component: TermsAndConditions,
          },
          {
            path: '',
            Component: AuthenticationChildrenContainer,
            children: [
              {
                path: 'login',
                Component: Login,
              },
              {
                path: 'register',
                Component: Register,
              },
              // {
              //   path: 'verification',
              //   Component: Verification,
              // },
              {
                path: 'verify',
                Component: VerifyOTP,
              },
            ],
          },
        ],
      },

      {
        path: '*',
        element: <div>404 page</div>,
      },
    ],
  },
]);
