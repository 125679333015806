import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, Typography } from '@mui/material';
import { startAuthentication } from '@simplewebauthn/browser';
import parsePhoneNumber from 'libphonenumber-js/mobile';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ZodType, z } from 'zod';
import { AppButton } from '~/components/AppButton';
import { AppInput } from '~/components/AppInput/AppInput';
import {
  useCompleteWebAuthnAuthenticationMutation,
  useStartWebAuthnAuthenticationLazyQuery,
} from '~/graphql/generated';
import { usePhoneHook } from '~/hooks/phoneHook';
import { checkWebAuthnSupport } from '~/utils/webauthn-support';

export interface ILoginFormData {
  phone: string;
}

const schema: ZodType<ILoginFormData> = z.object({
  phone: z
    .string({
      required_error: 'Ovo polje je obavezno',
    })
    .nonempty('Ovo polje je obavezno')
    .refine((value) => {
      const phoneNumber = parsePhoneNumber(value, 'HR');

      return phoneNumber?.getType() === 'MOBILE' && phoneNumber?.isValid();
    }, 'Neispravan format mobilnog broja'),
});

export default function Login() {
  const navigate = useNavigate();
  const [startWebAuthnAuth] = useStartWebAuthnAuthenticationLazyQuery({
    onError(error) {
      if (error.graphQLErrors.length === 1) {
        const noWebAuthnDeviceError = error.graphQLErrors[0].extensions?.code;
        if (noWebAuthnDeviceError === 'NoWebAuthnDevice') {
          const message = 'Promijenili smo način autentikacije! Molimo vas da ponovno registrirate ovaj uređaj.';
          enqueueSnackbar(message, {
            variant: 'warning',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            autoHideDuration: 10000,
          });
          // navigate to register and add query param to tell it's a new device
          navigate('/authentication/register?newDevice=true');
        }
      }
    },
  });
  const [completeWebAuthnAuth] = useCompleteWebAuthnAuthenticationMutation();
  const webAuthnSupport = checkWebAuthnSupport();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ILoginFormData>({
    mode: 'onTouched',
    resolver: zodResolver(schema),
    defaultValues: {
      phone: '',
    },
  });

  const { phoneField, mask } = usePhoneHook(control);

  const handleWebAuthnLogin = async (phoneNumber: string) => {
    try {
      // Start WebAuthn authentication
      const { data } = await startWebAuthnAuth({
        variables: { phoneNumber },
      });

      if (!data?.startWebAuthnAuthentication.success || !data.startWebAuthnAuthentication.options) {
        throw new Error('Failed to start WebAuthn authentication');
      }

      // Create credentials using SimpleWebAuthn
      const authenticationResponse = await startAuthentication({
        optionsJSON: data.startWebAuthnAuthentication.options,
      });

      // Complete WebAuthn authentication
      const result = await completeWebAuthnAuth({
        variables: {
          input: {
            phoneNumber,
            response: authenticationResponse,
          },
        },
      });

      if (result.data?.completeWebAuthnAuthentication.success) {
        // Handle successful authentication
        navigate('/'); // Or wherever you want to redirect
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('WebAuthn authentication failed:', error);
      // Handle error appropriately
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      // First try WebAuthn
      if (webAuthnSupport.isSupported) {
        await handleWebAuthnLogin(data.phone);
        return;
      }

      navigate('/authentication/verify', {
        state: {
          phone: data.phone,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to send OTP:', error);
    }
  });

  return (
    <Stack flex={1} justifyContent={'space-between'} height="100%">
      <AppInput
        ref={mask.ref}
        label="Unesite broj mobitela"
        value={phoneField.field.value}
        onBlur={phoneField.field.onBlur}
        name={phoneField.field.name}
        onChange={() => {
          //
        }}
        textFieldProps={{
          placeholder: '09x xxx xx xx',
          error: !!errors.phone,
          helperText: errors.phone?.message,
        }}
      />

      <Stack gap={4}>
        <Stack direction={'row'} gap={0.5} justifyContent={'center'}>
          <Typography variant="body2" fontWeight={500}>
            Nemate račun?
          </Typography>
          <Typography
            variant="body2"
            fontWeight={600}
            color={'primary.main'}
            onClick={() => navigate('/authentication/register')}
          >
            Registracija
          </Typography>
        </Stack>
        <AppButton variant="contained" size="large" disabled={!isValid} onClick={onSubmit}>
          Prijavi se
        </AppButton>
      </Stack>
    </Stack>
  );
}
