import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Container, Modal, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppButton } from '~/components/AppButton';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { ModalContent } from '~/components/ModalContent/ModalContent';
import { OtpCode } from '~/components/OtpCode/OtpCode';
import { useSendPhoneVerificationOtpMutation, useVerifyPhoneOtpMutation } from '~/graphql/generated';
import { errorFormatter } from '~/helpers/utils';
import { getOtpTiming, isInCooldown, removeOtpTiming, setOtpTiming } from '~/utils/otp';

export interface PhoneVerificationLocationState {
  phone: string;
}

export const PhoneVerification: FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);

  // Get the phone number from location state
  const { phone } = location.state as PhoneVerificationLocationState;

  // Keep OTP data in local state
  const [otpData, setOtpData] = useState<
    | {
        preAuthSessionId: string;
        deviceId: string;
      }
    | undefined
  >();

  // Initialize mutations
  const [sendPhoneVerificationOTP] = useSendPhoneVerificationOtpMutation({
    onError(error) {
      errorFormatter(error);
    },
  });
  const [verifyPhoneOTP] = useVerifyPhoneOtpMutation({
    onError(error) {
      errorFormatter(error);
    },
  });

  // Handle initial OTP send
  const handleSendOTP = async () => {
    if (isResending || isLoading) return;

    // Check if in cooldown period
    if (isInCooldown(phone)) {
      const timingData = getOtpTiming(phone);
      setOtpData(timingData?.otpData ? timingData?.otpData : otpData);
      return;
    }

    setIsResending(true);
    setError(undefined);

    try {
      setOtpTiming(phone);
      const result = await sendPhoneVerificationOTP({
        variables: {
          input: {
            newPhone: phone,
          },
        },
      });

      if (!result.data) {
        throw new Error('Greška pri slanju verifikacijskog koda');
      }

      setOtpData({
        preAuthSessionId: result.data.sendPhoneVerificationOTP.preAuthSessionId,
        deviceId: result.data.sendPhoneVerificationOTP.deviceId,
      });

      const newOtpData = {
        preAuthSessionId: result.data.sendPhoneVerificationOTP.preAuthSessionId,
        deviceId: result.data.sendPhoneVerificationOTP.deviceId,
      };

      setOtpData(newOtpData);
      // Update timing data with OTP data
      setOtpTiming(phone, newOtpData);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Greška pri slanju verifikacijskog koda');
    } finally {
      setIsResending(false);
    }
  };

  // Handle OTP verification
  const handleVerification = async (code: string) => {
    if (!otpData) {
      setError('Molimo zatražite verifikacijski kod');
      return;
    }

    setIsLoading(true);
    setError(undefined);

    try {
      const verifyResult = await verifyPhoneOTP({
        variables: {
          input: {
            newPhone: phone,
            preAuthSessionId: otpData.preAuthSessionId,
            deviceId: otpData.deviceId,
            userInputCode: code,
          },
        },
      });

      if (verifyResult.data?.verifyPhoneOTP.success) {
        removeOtpTiming(phone);
        setOpenModal(true);
      } else {
        throw new Error(verifyResult.data?.verifyPhoneOTP.message || 'Verifikacija nije uspjela');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Verifikacija nije uspjela');
    } finally {
      setIsLoading(false);
    }
  };

  // Send initial OTP when component mounts
  useEffect(() => {
    if (!otpData) {
      handleSendOTP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle manual resend
  const handleResend = () => handleSendOTP();

  const handleModal = () => {
    setOpenModal(false);
    navigate('/settings');
  };

  return (
    <>
      <AppHeader />
      <Container sx={{ flex: 1, display: 'flex' }}>
        <Stack gap={10} alignItems={'center'} pt={2.5} flex={1}>
          <Typography fontWeight={'bold'} variant="h4">
            Verifikacija
          </Typography>
          <OtpCode
            onResend={handleResend} // Use handleResend for manual resends
            phone={phone}
            onSubmit={handleVerification}
            loading={isLoading || isResending}
            error={error}
            timingData={getOtpTiming(phone)}
          />
        </Stack>
      </Container>
      <Modal open={openModal} onClose={handleModal}>
        <div>
          <ModalContent
            title="Spremljeno"
            description="Uspješno ste promijenili Vaš broj mobilnog telefona."
            iconColor="success.main"
            icon={CheckCircleRoundedIcon}
            children={
              <AppButton variant="contained" size="large" fullWidth onClick={handleModal}>
                Natrag na postavke
              </AppButton>
            }
          />
        </div>
      </Modal>
    </>
  );
};
