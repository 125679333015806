import { useContext, useEffect, useState } from 'react';
import Session from 'supertokens-web-js/recipe/session';
import { useNavigate } from 'react-router-dom';
import { checkWebAuthnSupport } from '../utils/webauthn-support';
import { AuthContext } from '~/contexts/authContextCore';

interface SessionStatus {
  exists: boolean;
  loading: boolean;
  error?: Error;
  needsWebAuthn?: boolean;
}

export const useSessionCheck = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<SessionStatus>({
    exists: false,
    loading: true,
  });
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const checkSession = async () => {
      try {
        if (authContext.mounting) {
          return;
        }
        if (!authContext.isLoggedIn) {
          return;
        }
        const exists = await Session.doesSessionExist();

        if (exists) {
          // Check if user has WebAuthn set up
          // This should be an API call to your backend to check if the user has registered WebAuthn credentials
          // const hasWebAuthn = await fetch('/api/auth/webauthn/status', {
          //   headers: {
          //     Authorization: `Bearer ${accessToken}`,
          //   },
          // }).then((res) => res.json());

          const webAuthnSupport = checkWebAuthnSupport();

          // If user doesn't have WebAuthn set up and browser supports it, mark as needing setup
          // const needsWebAuthn = !hasWebAuthn.registered && webAuthnSupport.isSupported;
          const needsWebAuthn = webAuthnSupport.isSupported;

          setStatus({
            exists,
            loading: false,
            needsWebAuthn,
          });

          // Automatically redirect to WebAuthn setup if needed
          if (needsWebAuthn && !window.location.pathname.includes('/setup-webauthn')) {
            navigate('/setup-webauthn', { replace: true });
          }
        } else {
          setStatus({
            exists,
            loading: false,
          });
        }
      } catch (error) {
        setStatus({
          exists: false,
          loading: false,
          error: error instanceof Error ? error : new Error('Session check failed'),
        });
      }
    };

    checkSession();
  }, [authContext.isLoggedIn, authContext.mounting, navigate]);

  return status;
};
