import { FC, useEffect, useMemo, useState } from 'react';
import { signOut, useSessionContext } from 'supertokens-auth-react/recipe/session';
import { Client, MeDataQuery, useMeDataLazyQuery } from '~/graphql/generated';
import { useSessionCheck } from '~/hooks/useSessionCheck';
import { AuthContext } from './authContextCore';

export const AuthProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [user, setUser] = useState<Client | null>(null);

  const session = useSessionContext();
  useSessionCheck();

  useEffect(() => {
    if (session.loading) {
      setUser(null);
    } else if (session.accessTokenPayload?.client) {
      setUser(session.accessTokenPayload.client);
    } else {
      setMounting(false);
    }
  }, [session]);
  const [userData, setUserData] = useState<MeDataQuery['user']>();
  const [mounting, setMounting] = useState(true);

  const [fetchMeData, { data: meData }] = useMeDataLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.user) {
        setUserData(data.user);
      }
      setMounting(false);
    },
    onError() {
      setMounting(false);
      return 'null';
    },
  });

  useEffect(() => {
    setUserData(meData?.user);
  }, [meData?.user]);

  useEffect(() => {
    if (user) {
      fetchMeData();
    }
  }, [fetchMeData, user]);

  const logoutUser = async () => {
    await signOut().then(() => {
      setUser(null);
      setUserData(undefined);
    });
  };

  const isLoggedIn = useMemo(() => !!(user && userData), [user, userData]);

  const value = useMemo(
    () => ({ isLoggedIn, logoutUser, mounting: mounting, user, userData }),
    [user, mounting, userData, isLoggedIn],
  );

  return <AuthContext.Provider value={value}>{mounting ? null : children}</AuthContext.Provider>;
};
