import { zodResolver } from '@hookform/resolvers/zod';
import { Container, Stack } from '@mui/material';
import { parsePhoneNumber } from 'libphonenumber-js/mobile';
import { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ZodType, z } from 'zod';
import { AppButton } from '~/components/AppButton';
import { AppHeader } from '~/components/AppHeader/AppHeader';
import { AppInput } from '~/components/AppInput/AppInput';
import { AppLabel } from '~/components/AppLabel/AppLabel';
import { AuthContext } from '~/contexts/authContextCore';
import { usePhoneExistsLazyQuery } from '~/graphql/generated';
import { usePhoneHook } from '~/hooks/phoneHook';
import { PhoneVerificationLocationState } from './PhoneVerification';

export interface ChangePhoneFormData {
  phone: string;
}

const schema: ZodType<ChangePhoneFormData> = z.object({
  phone: z
    .string({
      required_error: 'Ovo polje je obavezno',
    })
    .nonempty('Ovo polje je obavezno')
    .refine((value) => {
      const phoneNumber = parsePhoneNumber(value, 'HR');

      return phoneNumber?.getType() === 'MOBILE' && phoneNumber?.isValid();
    }, 'Neispravan format mobilnog broja'),
});

export const EditPhone: FC = () => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<ChangePhoneFormData>({
    mode: 'onTouched',
    resolver: zodResolver(schema),
    defaultValues: {
      phone: userData?.phone || '',
    },
  });

  const { phoneField, mask } = usePhoneHook(control);

  const [checkIfPhoneExists, phoneExistsOptions] = usePhoneExistsLazyQuery({
    onCompleted(data) {
      const values = getValues();

      if (!data?.phoneExists) {
        navigate('/settings/phone/verification', {
          state: { phone: values.phone } as PhoneVerificationLocationState,
        });
      }
    },
  });

  const { data: phoneExistsData, loading } = phoneExistsOptions;

  const onSubmit = handleSubmit(async (data) => {
    checkIfPhoneExists({
      variables: {
        phone: data.phone,
      },
    });
  });

  let errorMessage = '';

  if (errors.phone?.message || phoneExistsData?.phoneExists) {
    if (errors.phone?.message) {
      errorMessage = errors.phone?.message;
    } else if (phoneExistsData?.phoneExists) {
      errorMessage = 'Ovaj broj je već zauzet';
    }
  }

  return (
    <>
      <AppHeader />
      <AppLabel text="Uredi broj" />
      <Container
        sx={{
          flex: 1,
        }}
      >
        <Stack justifyContent={'space-between'} width={'100%'} height={'100%'}>
          <AppInput
            ref={mask.ref}
            label="Unesite broj mobitela"
            value={phoneField.field.value}
            onBlur={phoneField.field.onBlur}
            name={phoneField.field.name}
            onChange={async () => {
              //
            }}
            textFieldProps={{
              placeholder: '09x xxx xx xx',
              error: !!errors.phone || phoneExistsData?.phoneExists,
              helperText: errorMessage,
            }}
          />
          <AppButton
            variant="contained"
            size="large"
            disabled={!isValid || phoneField.field.value === userData?.phone}
            fullWidth
            onClick={onSubmit}
            loading={loading}
          >
            Nastavi
          </AppButton>
        </Stack>
      </Container>
    </>
  );
};
