/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Byte: { input: any; output: any; }
  DateTimeISO: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type AdditionalService = {
  __typename?: 'AdditionalService';
  AdditionalServiceVersion: Array<AdditionalServiceVersion>;
  _count?: Maybe<AdditionalServiceCount>;
  description: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};


export type AdditionalServiceAdditionalServiceVersionArgs = {
  cursor?: InputMaybe<AdditionalServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdditionalServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdditionalServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdditionalServiceVersionWhereInput>;
};

export type AdditionalServiceCount = {
  __typename?: 'AdditionalServiceCount';
  AdditionalServiceVersion: Scalars['Int']['output'];
};


export type AdditionalServiceCountAdditionalServiceVersionArgs = {
  where?: InputMaybe<AdditionalServiceVersionWhereInput>;
};

export type AdditionalServiceNullableRelationFilter = {
  is?: InputMaybe<AdditionalServiceWhereInput>;
  isNot?: InputMaybe<AdditionalServiceWhereInput>;
};

export type AdditionalServiceOrderByWithRelationInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export type AdditionalServiceVersion = {
  __typename?: 'AdditionalServiceVersion';
  additionalService?: Maybe<AdditionalService>;
  barberEditor?: Maybe<Barber>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  versionAdditionalServiceId?: Maybe<Scalars['Int']['output']>;
  versionBarberEditorId?: Maybe<Scalars['Int']['output']>;
  versionId: Scalars['Int']['output'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO']['output'];
};


export type AdditionalServiceVersionAdditionalServiceArgs = {
  where?: InputMaybe<AdditionalServiceWhereInput>;
};


export type AdditionalServiceVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};

export type AdditionalServiceVersionListRelationFilter = {
  every?: InputMaybe<AdditionalServiceVersionWhereInput>;
  none?: InputMaybe<AdditionalServiceVersionWhereInput>;
  some?: InputMaybe<AdditionalServiceVersionWhereInput>;
};

export type AdditionalServiceVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AdditionalServiceVersionOrderByWithRelationInput = {
  additionalService?: InputMaybe<AdditionalServiceOrderByWithRelationInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  description?: InputMaybe<SortOrderInput>;
  duration?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrderInput>;
  versionAdditionalServiceId?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum AdditionalServiceVersionScalarFieldEnum {
  Description = 'description',
  Duration = 'duration',
  Id = 'id',
  Name = 'name',
  Price = 'price',
  VersionAdditionalServiceId = 'versionAdditionalServiceId',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type AdditionalServiceVersionWhereInput = {
  AND?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  additionalService?: InputMaybe<AdditionalServiceNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  versionAdditionalServiceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AdditionalServiceVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<AdditionalServiceVersionWhereInput>>;
  additionalService?: InputMaybe<AdditionalServiceNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  versionAdditionalServiceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AdditionalServiceWhereInput = {
  AND?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionListRelationFilter>;
  NOT?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  OR?: InputMaybe<Array<AdditionalServiceWhereInput>>;
  description?: InputMaybe<StringFilter>;
  duration?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<IntFilter>;
};

export type Appointment = {
  __typename?: 'Appointment';
  AppointmentVersion: Array<AppointmentVersion>;
  _count?: Maybe<AppointmentCount>;
  barber: Barber;
  barberId: Scalars['Int']['output'];
  barberNote?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']['output']>;
  end: Scalars['DateTimeISO']['output'];
  id: Scalars['Int']['output'];
  isPause?: Maybe<Scalars['Boolean']['output']>;
  phoneContact?: Maybe<Scalars['String']['output']>;
  price: Scalars['Decimal']['output'];
  repeatId?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['Int']['output']>;
  start: Scalars['DateTimeISO']['output'];
};


export type AppointmentAppointmentVersionArgs = {
  cursor?: InputMaybe<AppointmentVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppointmentVersionWhereInput>;
};


export type AppointmentClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type AppointmentServiceArgs = {
  where?: InputMaybe<ServiceWhereInput>;
};

export type AppointmentCount = {
  __typename?: 'AppointmentCount';
  AppointmentVersion: Scalars['Int']['output'];
};


export type AppointmentCountAppointmentVersionArgs = {
  where?: InputMaybe<AppointmentVersionWhereInput>;
};

export type AppointmentListRelationFilter = {
  every?: InputMaybe<AppointmentWhereInput>;
  none?: InputMaybe<AppointmentWhereInput>;
  some?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentNullableRelationFilter = {
  is?: InputMaybe<AppointmentWhereInput>;
  isNot?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AppointmentOrderByWithRelationInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionOrderByRelationAggregateInput>;
  barber?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrder>;
  barberNote?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrderInput>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPause?: InputMaybe<SortOrderInput>;
  phoneContact?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrder>;
  repeatId?: InputMaybe<SortOrderInput>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  serviceId?: InputMaybe<SortOrderInput>;
  start?: InputMaybe<SortOrder>;
};

export enum AppointmentScalarFieldEnum {
  BarberId = 'barberId',
  BarberNote = 'barberNote',
  ClientId = 'clientId',
  End = 'end',
  Id = 'id',
  IsPause = 'isPause',
  PhoneContact = 'phoneContact',
  Price = 'price',
  RepeatId = 'repeatId',
  ServiceId = 'serviceId',
  Start = 'start'
}

export type AppointmentVersion = {
  __typename?: 'AppointmentVersion';
  appointment?: Maybe<Appointment>;
  barberEditor?: Maybe<Barber>;
  barberId?: Maybe<Scalars['Int']['output']>;
  barberNote?: Maybe<Scalars['String']['output']>;
  clientEditor?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']['output']>;
  end?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['Int']['output'];
  isPause?: Maybe<Scalars['Boolean']['output']>;
  phoneContact?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Decimal']['output']>;
  repeatId?: Maybe<Scalars['String']['output']>;
  serviceId?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['DateTimeISO']['output']>;
  versionAppointmentId?: Maybe<Scalars['Int']['output']>;
  versionBarberEditorId?: Maybe<Scalars['Int']['output']>;
  versionClientEditorId?: Maybe<Scalars['Int']['output']>;
  versionId: Scalars['Int']['output'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO']['output'];
};


export type AppointmentVersionAppointmentArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};


export type AppointmentVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type AppointmentVersionClientEditorArgs = {
  where?: InputMaybe<ClientWhereInput>;
};

export type AppointmentVersionListRelationFilter = {
  every?: InputMaybe<AppointmentVersionWhereInput>;
  none?: InputMaybe<AppointmentVersionWhereInput>;
  some?: InputMaybe<AppointmentVersionWhereInput>;
};

export type AppointmentVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AppointmentVersionOrderByWithRelationInput = {
  appointment?: InputMaybe<AppointmentOrderByWithRelationInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrderInput>;
  barberNote?: InputMaybe<SortOrderInput>;
  clientEditor?: InputMaybe<ClientOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrderInput>;
  end?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isPause?: InputMaybe<SortOrderInput>;
  phoneContact?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrderInput>;
  repeatId?: InputMaybe<SortOrderInput>;
  serviceId?: InputMaybe<SortOrderInput>;
  start?: InputMaybe<SortOrderInput>;
  versionAppointmentId?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionClientEditorId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum AppointmentVersionScalarFieldEnum {
  BarberId = 'barberId',
  BarberNote = 'barberNote',
  ClientId = 'clientId',
  End = 'end',
  Id = 'id',
  IsPause = 'isPause',
  PhoneContact = 'phoneContact',
  Price = 'price',
  RepeatId = 'repeatId',
  ServiceId = 'serviceId',
  Start = 'start',
  VersionAppointmentId = 'versionAppointmentId',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionClientEditorId = 'versionClientEditorId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type AppointmentVersionWhereInput = {
  AND?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  OR?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  appointment?: InputMaybe<AppointmentNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  clientEditor?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalNullableFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionAppointmentId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AppointmentVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  OR?: InputMaybe<Array<AppointmentVersionWhereInput>>;
  appointment?: InputMaybe<AppointmentNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  clientEditor?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalNullableFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionAppointmentId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type AppointmentWhereInput = {
  AND?: InputMaybe<Array<AppointmentWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  NOT?: InputMaybe<Array<AppointmentWhereInput>>;
  OR?: InputMaybe<Array<AppointmentWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type AppointmentWhereUniqueInput = {
  AND?: InputMaybe<Array<AppointmentWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  NOT?: InputMaybe<Array<AppointmentWhereInput>>;
  OR?: InputMaybe<Array<AppointmentWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberNote?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isPause?: InputMaybe<BoolNullableFilter>;
  phoneContact?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<DecimalFilter>;
  repeatId?: InputMaybe<StringNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export enum AuditOperation {
  Delete = 'DELETE',
  Insert = 'INSERT',
  Update = 'UPDATE'
}

export type AuthResponse = {
  __typename?: 'AuthResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  user?: Maybe<Client>;
};

export type AvailableTimeSlot = IAvailableTimeSlot & {
  __typename?: 'AvailableTimeSlot';
  barber: ClientBarber;
  barberId: Scalars['Int']['output'];
  date: Scalars['DateTimeISO']['output'];
  service: ClientService;
  serviceId: Scalars['Int']['output'];
  timeSlots: Array<TimeSlot>;
};

export type AvailableTimeSlotsInput = {
  barberId: Scalars['Int']['input'];
  dates: Array<Scalars['DateTimeISO']['input']>;
  serviceId: Scalars['Int']['input'];
};

export type Barber = {
  __typename?: 'Barber';
  AdditionalServiceVersion: Array<AdditionalServiceVersion>;
  AppointmentVersion: Array<AppointmentVersion>;
  BarberEditorVersion: Array<BarberVersion>;
  BarberServiceVersion: Array<BarberServiceVersion>;
  BarberVersion: Array<BarberVersion>;
  BarberWorkTimeOverrideVersion: Array<BarberWorkTimeOverrideVersion>;
  BarberWorkTimeVersion: Array<BarberWorkTimeVersion>;
  Client: Array<Client>;
  ClientVersion: Array<ClientVersion>;
  PenaltyVersion: Array<PenaltyVersion>;
  ServiceVersion: Array<ServiceVersion>;
  _count?: Maybe<BarberCount>;
  active: Scalars['Boolean']['output'];
  appointments: Array<Appointment>;
  barberServices: Array<BarberService>;
  barberWorkTimeOverrides: Array<BarberWorkTimeOverride>;
  barberWorkTimes: Array<BarberWorkTime>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  type: BarberType;
};


export type BarberAdditionalServiceVersionArgs = {
  cursor?: InputMaybe<AdditionalServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdditionalServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdditionalServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdditionalServiceVersionWhereInput>;
};


export type BarberAppointmentVersionArgs = {
  cursor?: InputMaybe<AppointmentVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppointmentVersionWhereInput>;
};


export type BarberBarberEditorVersionArgs = {
  cursor?: InputMaybe<BarberVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberVersionWhereInput>;
};


export type BarberBarberServiceVersionArgs = {
  cursor?: InputMaybe<BarberServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberServiceVersionWhereInput>;
};


export type BarberBarberVersionArgs = {
  cursor?: InputMaybe<BarberVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberVersionWhereInput>;
};


export type BarberBarberWorkTimeOverrideVersionArgs = {
  cursor?: InputMaybe<BarberWorkTimeOverrideVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeOverrideVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeOverrideVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};


export type BarberBarberWorkTimeVersionArgs = {
  cursor?: InputMaybe<BarberWorkTimeVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};


export type BarberClientArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type BarberClientVersionArgs = {
  cursor?: InputMaybe<ClientVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type BarberPenaltyVersionArgs = {
  cursor?: InputMaybe<PenaltyVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PenaltyVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PenaltyVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PenaltyVersionWhereInput>;
};


export type BarberServiceVersionArgs = {
  cursor?: InputMaybe<ServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceVersionWhereInput>;
};


export type BarberAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppointmentWhereInput>;
};


export type BarberBarberServicesArgs = {
  cursor?: InputMaybe<BarberServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberServiceWhereInput>;
};


export type BarberBarberWorkTimeOverridesArgs = {
  cursor?: InputMaybe<BarberWorkTimeOverrideWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeOverrideScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeOverrideOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};


export type BarberBarberWorkTimesArgs = {
  cursor?: InputMaybe<BarberWorkTimeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberCount = {
  __typename?: 'BarberCount';
  AdditionalServiceVersion: Scalars['Int']['output'];
  AppointmentVersion: Scalars['Int']['output'];
  BarberEditorVersion: Scalars['Int']['output'];
  BarberServiceVersion: Scalars['Int']['output'];
  BarberVersion: Scalars['Int']['output'];
  BarberWorkTimeOverrideVersion: Scalars['Int']['output'];
  BarberWorkTimeVersion: Scalars['Int']['output'];
  Client: Scalars['Int']['output'];
  ClientVersion: Scalars['Int']['output'];
  PenaltyVersion: Scalars['Int']['output'];
  ServiceVersion: Scalars['Int']['output'];
  appointments: Scalars['Int']['output'];
  barberServices: Scalars['Int']['output'];
  barberWorkTimeOverrides: Scalars['Int']['output'];
  barberWorkTimes: Scalars['Int']['output'];
};


export type BarberCountAdditionalServiceVersionArgs = {
  where?: InputMaybe<AdditionalServiceVersionWhereInput>;
};


export type BarberCountAppointmentVersionArgs = {
  where?: InputMaybe<AppointmentVersionWhereInput>;
};


export type BarberCountBarberEditorVersionArgs = {
  where?: InputMaybe<BarberVersionWhereInput>;
};


export type BarberCountBarberServiceVersionArgs = {
  where?: InputMaybe<BarberServiceVersionWhereInput>;
};


export type BarberCountBarberVersionArgs = {
  where?: InputMaybe<BarberVersionWhereInput>;
};


export type BarberCountBarberWorkTimeOverrideVersionArgs = {
  where?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};


export type BarberCountBarberWorkTimeVersionArgs = {
  where?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};


export type BarberCountClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type BarberCountClientVersionArgs = {
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type BarberCountPenaltyVersionArgs = {
  where?: InputMaybe<PenaltyVersionWhereInput>;
};


export type BarberCountServiceVersionArgs = {
  where?: InputMaybe<ServiceVersionWhereInput>;
};


export type BarberCountAppointmentsArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};


export type BarberCountBarberServicesArgs = {
  where?: InputMaybe<BarberServiceWhereInput>;
};


export type BarberCountBarberWorkTimeOverridesArgs = {
  where?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};


export type BarberCountBarberWorkTimesArgs = {
  where?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberNullableRelationFilter = {
  is?: InputMaybe<BarberWhereInput>;
  isNot?: InputMaybe<BarberWhereInput>;
};

export type BarberOrderByWithRelationInput = {
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionOrderByRelationAggregateInput>;
  AppointmentVersion?: InputMaybe<AppointmentVersionOrderByRelationAggregateInput>;
  BarberEditorVersion?: InputMaybe<BarberVersionOrderByRelationAggregateInput>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionOrderByRelationAggregateInput>;
  BarberVersion?: InputMaybe<BarberVersionOrderByRelationAggregateInput>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionOrderByRelationAggregateInput>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionOrderByRelationAggregateInput>;
  Client?: InputMaybe<ClientOrderByRelationAggregateInput>;
  ClientVersion?: InputMaybe<ClientVersionOrderByRelationAggregateInput>;
  PenaltyVersion?: InputMaybe<PenaltyVersionOrderByRelationAggregateInput>;
  ServiceVersion?: InputMaybe<ServiceVersionOrderByRelationAggregateInput>;
  active?: InputMaybe<SortOrder>;
  appointments?: InputMaybe<AppointmentOrderByRelationAggregateInput>;
  barberServices?: InputMaybe<BarberServiceOrderByRelationAggregateInput>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideOrderByRelationAggregateInput>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  uid?: InputMaybe<SortOrder>;
};

export type BarberRelationFilter = {
  is?: InputMaybe<BarberWhereInput>;
  isNot?: InputMaybe<BarberWhereInput>;
};

export type BarberService = {
  __typename?: 'BarberService';
  BarberServiceVersion: Array<BarberServiceVersion>;
  _count?: Maybe<BarberServiceCount>;
  assignedAt: Scalars['DateTimeISO']['output'];
  barber: Barber;
  barberId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  service: Service;
  serviceId: Scalars['Int']['output'];
};


export type BarberServiceBarberServiceVersionArgs = {
  cursor?: InputMaybe<BarberServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberServiceVersionWhereInput>;
};

export type BarberServiceBarberIdServiceIdCompoundUniqueInput = {
  barberId: Scalars['Int']['input'];
  serviceId: Scalars['Int']['input'];
};

export type BarberServiceCount = {
  __typename?: 'BarberServiceCount';
  BarberServiceVersion: Scalars['Int']['output'];
};


export type BarberServiceCountBarberServiceVersionArgs = {
  where?: InputMaybe<BarberServiceVersionWhereInput>;
};

export type BarberServiceListRelationFilter = {
  every?: InputMaybe<BarberServiceWhereInput>;
  none?: InputMaybe<BarberServiceWhereInput>;
  some?: InputMaybe<BarberServiceWhereInput>;
};

export type BarberServiceNullableRelationFilter = {
  is?: InputMaybe<BarberServiceWhereInput>;
  isNot?: InputMaybe<BarberServiceWhereInput>;
};

export type BarberServiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberServiceOrderByWithRelationInput = {
  BarberServiceVersion?: InputMaybe<BarberServiceVersionOrderByRelationAggregateInput>;
  assignedAt?: InputMaybe<SortOrder>;
  barber?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  serviceId?: InputMaybe<SortOrder>;
};

export enum BarberServiceScalarFieldEnum {
  AssignedAt = 'assignedAt',
  BarberId = 'barberId',
  Id = 'id',
  ServiceId = 'serviceId'
}

export type BarberServiceVersion = {
  __typename?: 'BarberServiceVersion';
  assignedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  barberEditor?: Maybe<Barber>;
  barberId?: Maybe<Scalars['Int']['output']>;
  barberService?: Maybe<BarberService>;
  id: Scalars['Int']['output'];
  serviceId?: Maybe<Scalars['Int']['output']>;
  versionBarberEditorId?: Maybe<Scalars['Int']['output']>;
  versionBarberServiceId?: Maybe<Scalars['Int']['output']>;
  versionId: Scalars['Int']['output'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO']['output'];
};


export type BarberServiceVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type BarberServiceVersionBarberServiceArgs = {
  where?: InputMaybe<BarberServiceWhereInput>;
};

export type BarberServiceVersionListRelationFilter = {
  every?: InputMaybe<BarberServiceVersionWhereInput>;
  none?: InputMaybe<BarberServiceVersionWhereInput>;
  some?: InputMaybe<BarberServiceVersionWhereInput>;
};

export type BarberServiceVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberServiceVersionOrderByWithRelationInput = {
  assignedAt?: InputMaybe<SortOrderInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrderInput>;
  barberService?: InputMaybe<BarberServiceOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionBarberServiceId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum BarberServiceVersionScalarFieldEnum {
  AssignedAt = 'assignedAt',
  BarberId = 'barberId',
  Id = 'id',
  ServiceId = 'serviceId',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionBarberServiceId = 'versionBarberServiceId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type BarberServiceVersionWhereInput = {
  AND?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  assignedAt?: InputMaybe<DateTimeNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberService?: InputMaybe<BarberServiceNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberServiceId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberServiceVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceVersionWhereInput>>;
  assignedAt?: InputMaybe<DateTimeNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberService?: InputMaybe<BarberServiceNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberServiceId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberServiceWhereInput = {
  AND?: InputMaybe<Array<BarberServiceWhereInput>>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberServiceWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntFilter>;
};

export type BarberServiceWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberServiceWhereInput>>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberServiceWhereInput>>;
  OR?: InputMaybe<Array<BarberServiceWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberId_serviceId?: InputMaybe<BarberServiceBarberIdServiceIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntFilter>;
};

export enum BarberType {
  Admin = 'ADMIN',
  Normal = 'NORMAL'
}

export type BarberVersion = {
  __typename?: 'BarberVersion';
  active?: Maybe<Scalars['Boolean']['output']>;
  barber?: Maybe<Barber>;
  barberEditor?: Maybe<Barber>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<BarberType>;
  uid?: Maybe<Scalars['String']['output']>;
  versionBarberEditorId?: Maybe<Scalars['Int']['output']>;
  versionBarberId?: Maybe<Scalars['Int']['output']>;
  versionId: Scalars['Int']['output'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO']['output'];
};


export type BarberVersionBarberArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type BarberVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};

export type BarberVersionListRelationFilter = {
  every?: InputMaybe<BarberVersionWhereInput>;
  none?: InputMaybe<BarberVersionWhereInput>;
  some?: InputMaybe<BarberVersionWhereInput>;
};

export type BarberVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberVersionOrderByWithRelationInput = {
  active?: InputMaybe<SortOrderInput>;
  barber?: InputMaybe<BarberOrderByWithRelationInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrderInput>;
  uid?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionBarberId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum BarberVersionScalarFieldEnum {
  Active = 'active',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  Uid = 'uid',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionBarberId = 'versionBarberId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type BarberVersionWhereInput = {
  AND?: InputMaybe<Array<BarberVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barber?: InputMaybe<BarberNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBarberTypeNullableFilter>;
  uid?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barber?: InputMaybe<BarberNullableRelationFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumBarberTypeNullableFilter>;
  uid?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWhereInput = {
  AND?: InputMaybe<Array<BarberWhereInput>>;
  AdditionalServiceVersion?: InputMaybe<AdditionalServiceVersionListRelationFilter>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  BarberEditorVersion?: InputMaybe<BarberVersionListRelationFilter>;
  BarberServiceVersion?: InputMaybe<BarberServiceVersionListRelationFilter>;
  BarberVersion?: InputMaybe<BarberVersionListRelationFilter>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionListRelationFilter>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionListRelationFilter>;
  Client?: InputMaybe<ClientListRelationFilter>;
  ClientVersion?: InputMaybe<ClientVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWhereInput>>;
  OR?: InputMaybe<Array<BarberWhereInput>>;
  PenaltyVersion?: InputMaybe<PenaltyVersionListRelationFilter>;
  ServiceVersion?: InputMaybe<ServiceVersionListRelationFilter>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  barberServices?: InputMaybe<BarberServiceListRelationFilter>;
  barberWorkTimeOverrides?: InputMaybe<BarberWorkTimeOverrideListRelationFilter>;
  barberWorkTimes?: InputMaybe<BarberWorkTimeListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumBarberTypeFilter>;
  uid?: InputMaybe<StringFilter>;
};

export type BarberWorkTime = {
  __typename?: 'BarberWorkTime';
  BarberWorkTimeVersion: Array<BarberWorkTimeVersion>;
  _count?: Maybe<BarberWorkTimeCount>;
  barber: Barber;
  barberId: Scalars['Int']['output'];
  dayOfWeek: Scalars['Int']['output'];
  end: Scalars['DateTimeISO']['output'];
  id: Scalars['Int']['output'];
  start: Scalars['DateTimeISO']['output'];
};


export type BarberWorkTimeBarberWorkTimeVersionArgs = {
  cursor?: InputMaybe<BarberWorkTimeVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};

export type BarberWorkTimeBarberIdDayOfWeekStartEndCompoundUniqueInput = {
  barberId: Scalars['Int']['input'];
  dayOfWeek: Scalars['Int']['input'];
  end: Scalars['DateTimeISO']['input'];
  start: Scalars['DateTimeISO']['input'];
};

export type BarberWorkTimeCount = {
  __typename?: 'BarberWorkTimeCount';
  BarberWorkTimeVersion: Scalars['Int']['output'];
};


export type BarberWorkTimeCountBarberWorkTimeVersionArgs = {
  where?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};

export type BarberWorkTimeListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeWhereInput>;
  none?: InputMaybe<BarberWorkTimeWhereInput>;
  some?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberWorkTimeNullableRelationFilter = {
  is?: InputMaybe<BarberWorkTimeWhereInput>;
  isNot?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberWorkTimeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberWorkTimeOrderByWithRelationInput = {
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionOrderByRelationAggregateInput>;
  barber?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrder>;
  dayOfWeek?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
};

export type BarberWorkTimeOverride = {
  __typename?: 'BarberWorkTimeOverride';
  BarberWorkTimeOverrideVersion: Array<BarberWorkTimeOverrideVersion>;
  _count?: Maybe<BarberWorkTimeOverrideCount>;
  barber: Barber;
  barberId: Scalars['Int']['output'];
  date: Scalars['DateTimeISO']['output'];
  end?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['Int']['output'];
  isFreeDay?: Maybe<Scalars['Boolean']['output']>;
  start?: Maybe<Scalars['DateTimeISO']['output']>;
};


export type BarberWorkTimeOverrideBarberWorkTimeOverrideVersionArgs = {
  cursor?: InputMaybe<BarberWorkTimeOverrideVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberWorkTimeOverrideVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberWorkTimeOverrideVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};

export type BarberWorkTimeOverrideCount = {
  __typename?: 'BarberWorkTimeOverrideCount';
  BarberWorkTimeOverrideVersion: Scalars['Int']['output'];
};


export type BarberWorkTimeOverrideCountBarberWorkTimeOverrideVersionArgs = {
  where?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};

export type BarberWorkTimeOverrideListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  none?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  some?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};

export type BarberWorkTimeOverrideNullableRelationFilter = {
  is?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
  isNot?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};

export type BarberWorkTimeOverrideOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberWorkTimeOverrideOrderByWithRelationInput = {
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionOrderByRelationAggregateInput>;
  barber?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isFreeDay?: InputMaybe<SortOrderInput>;
  start?: InputMaybe<SortOrderInput>;
};

export enum BarberWorkTimeOverrideScalarFieldEnum {
  BarberId = 'barberId',
  Date = 'date',
  End = 'end',
  Id = 'id',
  IsFreeDay = 'isFreeDay',
  Start = 'start'
}

export type BarberWorkTimeOverrideVersion = {
  __typename?: 'BarberWorkTimeOverrideVersion';
  barberEditor?: Maybe<Barber>;
  barberId: Scalars['Int']['output'];
  barberWorkTimeOverride?: Maybe<BarberWorkTimeOverride>;
  date: Scalars['DateTimeISO']['output'];
  end?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['Int']['output'];
  isFreeDay?: Maybe<Scalars['Boolean']['output']>;
  start?: Maybe<Scalars['DateTimeISO']['output']>;
  versionBarberEditorId?: Maybe<Scalars['Int']['output']>;
  versionBarberWorkTimeOverrideId?: Maybe<Scalars['Int']['output']>;
  versionId: Scalars['Int']['output'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO']['output'];
};


export type BarberWorkTimeOverrideVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type BarberWorkTimeOverrideVersionBarberWorkTimeOverrideArgs = {
  where?: InputMaybe<BarberWorkTimeOverrideWhereInput>;
};

export type BarberWorkTimeOverrideVersionListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
  none?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
  some?: InputMaybe<BarberWorkTimeOverrideVersionWhereInput>;
};

export type BarberWorkTimeOverrideVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberWorkTimeOverrideVersionOrderByWithRelationInput = {
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrder>;
  barberWorkTimeOverride?: InputMaybe<BarberWorkTimeOverrideOrderByWithRelationInput>;
  date?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isFreeDay?: InputMaybe<SortOrderInput>;
  start?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionBarberWorkTimeOverrideId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum BarberWorkTimeOverrideVersionScalarFieldEnum {
  BarberId = 'barberId',
  Date = 'date',
  End = 'end',
  Id = 'id',
  IsFreeDay = 'isFreeDay',
  Start = 'start',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionBarberWorkTimeOverrideId = 'versionBarberWorkTimeOverrideId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type BarberWorkTimeOverrideVersionWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberWorkTimeOverride?: InputMaybe<BarberWorkTimeOverrideNullableRelationFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeOverrideId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeOverrideVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberWorkTimeOverride?: InputMaybe<BarberWorkTimeOverrideNullableRelationFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeOverrideId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeOverrideWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
};

export type BarberWorkTimeOverrideWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  BarberWorkTimeOverrideVersion?: InputMaybe<BarberWorkTimeOverrideVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeOverrideWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isFreeDay?: InputMaybe<BoolNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
};

export enum BarberWorkTimeScalarFieldEnum {
  BarberId = 'barberId',
  DayOfWeek = 'dayOfWeek',
  End = 'end',
  Id = 'id',
  Start = 'start'
}

export type BarberWorkTimeVersion = {
  __typename?: 'BarberWorkTimeVersion';
  barberEditor?: Maybe<Barber>;
  barberId?: Maybe<Scalars['Int']['output']>;
  barberWorkTime?: Maybe<BarberWorkTime>;
  dayOfWeek?: Maybe<Scalars['Int']['output']>;
  end?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['Int']['output'];
  start?: Maybe<Scalars['DateTimeISO']['output']>;
  versionBarberEditorId?: Maybe<Scalars['Int']['output']>;
  versionBarberWorkTimeId?: Maybe<Scalars['Int']['output']>;
  versionId: Scalars['Int']['output'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO']['output'];
};


export type BarberWorkTimeVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type BarberWorkTimeVersionBarberWorkTimeArgs = {
  where?: InputMaybe<BarberWorkTimeWhereInput>;
};

export type BarberWorkTimeVersionListRelationFilter = {
  every?: InputMaybe<BarberWorkTimeVersionWhereInput>;
  none?: InputMaybe<BarberWorkTimeVersionWhereInput>;
  some?: InputMaybe<BarberWorkTimeVersionWhereInput>;
};

export type BarberWorkTimeVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BarberWorkTimeVersionOrderByWithRelationInput = {
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  barberId?: InputMaybe<SortOrderInput>;
  barberWorkTime?: InputMaybe<BarberWorkTimeOrderByWithRelationInput>;
  dayOfWeek?: InputMaybe<SortOrderInput>;
  end?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionBarberWorkTimeId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum BarberWorkTimeVersionScalarFieldEnum {
  BarberId = 'barberId',
  DayOfWeek = 'dayOfWeek',
  End = 'end',
  Id = 'id',
  Start = 'start',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionBarberWorkTimeId = 'versionBarberWorkTimeId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type BarberWorkTimeVersionWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberWorkTime?: InputMaybe<BarberWorkTimeNullableRelationFilter>;
  dayOfWeek?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  NOT?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  barberId?: InputMaybe<IntNullableFilter>;
  barberWorkTime?: InputMaybe<BarberWorkTimeNullableRelationFilter>;
  dayOfWeek?: InputMaybe<IntNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionBarberWorkTimeId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeWhereInput = {
  AND?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  dayOfWeek?: InputMaybe<IntFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type BarberWorkTimeWhereUniqueInput = {
  AND?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  BarberWorkTimeVersion?: InputMaybe<BarberWorkTimeVersionListRelationFilter>;
  NOT?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  OR?: InputMaybe<Array<BarberWorkTimeWhereInput>>;
  barber?: InputMaybe<BarberRelationFilter>;
  barberId?: InputMaybe<IntFilter>;
  barberId_dayOfWeek_start_end?: InputMaybe<BarberWorkTimeBarberIdDayOfWeekStartEndCompoundUniqueInput>;
  dayOfWeek?: InputMaybe<IntFilter>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<DateTimeFilter>;
};

export type BigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type BookAppointmentInput = {
  barberId: Scalars['Int']['input'];
  date: Scalars['DateTimeISO']['input'];
  end: Scalars['DateTimeISO']['input'];
  serviceId: Scalars['Int']['input'];
  start: Scalars['DateTimeISO']['input'];
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BytesFilter = {
  equals?: InputMaybe<Scalars['Byte']['input']>;
  in?: InputMaybe<Array<Scalars['Byte']['input']>>;
  not?: InputMaybe<NestedBytesFilter>;
  notIn?: InputMaybe<Array<Scalars['Byte']['input']>>;
};

export type Client = {
  __typename?: 'Client';
  AppointmentVersion: Array<AppointmentVersion>;
  ClientVersion: Array<ClientVersion>;
  _count?: Maybe<ClientCount>;
  active: Scalars['Boolean']['output'];
  appointments: Array<Appointment>;
  blocked: Scalars['Boolean']['output'];
  blockedReason?: Maybe<Scalars['String']['output']>;
  clientEditorVersion: Array<ClientVersion>;
  favouriteBarber?: Maybe<Barber>;
  favouriteBarberId?: Maybe<Scalars['Int']['output']>;
  favouriteService?: Maybe<Service>;
  favouriteServiceId?: Maybe<Scalars['Int']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  penalties: Array<Penalty>;
  phone: Scalars['String']['output'];
  referralCode?: Maybe<Scalars['String']['output']>;
  referrerId?: Maybe<Scalars['Int']['output']>;
  smsNotification: Scalars['Boolean']['output'];
  supertokensId?: Maybe<Scalars['String']['output']>;
  tac?: Maybe<Scalars['Boolean']['output']>;
  userUID?: Maybe<Scalars['String']['output']>;
  verified: Scalars['Boolean']['output'];
  webAuthnDevices: Array<WebAuthnDevice>;
};


export type ClientAppointmentVersionArgs = {
  cursor?: InputMaybe<AppointmentVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppointmentVersionWhereInput>;
};


export type ClientClientVersionArgs = {
  cursor?: InputMaybe<ClientVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type ClientAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppointmentWhereInput>;
};


export type ClientClientEditorVersionArgs = {
  cursor?: InputMaybe<ClientVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type ClientFavouriteBarberArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type ClientFavouriteServiceArgs = {
  where?: InputMaybe<ServiceWhereInput>;
};


export type ClientPenaltiesArgs = {
  cursor?: InputMaybe<PenaltyWhereUniqueInput>;
  distinct?: InputMaybe<Array<PenaltyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PenaltyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PenaltyWhereInput>;
};


export type ClientWebAuthnDevicesArgs = {
  cursor?: InputMaybe<WebAuthnDeviceWhereUniqueInput>;
  distinct?: InputMaybe<Array<WebAuthnDeviceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WebAuthnDeviceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebAuthnDeviceWhereInput>;
};

export type ClientAppointment = {
  __typename?: 'ClientAppointment';
  barber: ClientBarber;
  barberId: Scalars['Int']['output'];
  clientId: Scalars['Int']['output'];
  end: Scalars['DateTimeISO']['output'];
  id: Scalars['Int']['output'];
  price: Scalars['Decimal']['output'];
  service: ClientService;
  serviceId: Scalars['Int']['output'];
  start: Scalars['DateTimeISO']['output'];
};

export type ClientAppointmentOrderByInput = {
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
};

export type ClientAppointmentWhereInput = {
  AND?: InputMaybe<Array<ClientAppointmentWhereInput>>;
  NOT?: InputMaybe<Array<ClientAppointmentWhereInput>>;
  OR?: InputMaybe<Array<ClientAppointmentWhereInput>>;
  end?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  start?: InputMaybe<DateTimeFilter>;
};

export type ClientBarber = {
  __typename?: 'ClientBarber';
  barberServices?: Maybe<Array<ClientBarberService>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ClientBarberService = {
  __typename?: 'ClientBarberService';
  assignedAt: Scalars['DateTimeISO']['output'];
  barber: ClientBarber;
  barberId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  service: ClientService;
  serviceId: Scalars['Int']['output'];
};

export type ClientClient = {
  __typename?: 'ClientClient';
  blocked: Scalars['Boolean']['output'];
  favouriteBarber?: Maybe<ClientBarber>;
  favouriteBarberId?: Maybe<Scalars['Int']['output']>;
  favouriteService?: Maybe<ClientService>;
  favouriteServiceId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  referralCode?: Maybe<Scalars['String']['output']>;
  smsNotification?: Maybe<Scalars['Boolean']['output']>;
  verified: Scalars['Boolean']['output'];
};

export type ClientCount = {
  __typename?: 'ClientCount';
  AppointmentVersion: Scalars['Int']['output'];
  ClientVersion: Scalars['Int']['output'];
  appointments: Scalars['Int']['output'];
  clientEditorVersion: Scalars['Int']['output'];
  penalties: Scalars['Int']['output'];
  webAuthnDevices: Scalars['Int']['output'];
};


export type ClientCountAppointmentVersionArgs = {
  where?: InputMaybe<AppointmentVersionWhereInput>;
};


export type ClientCountClientVersionArgs = {
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type ClientCountAppointmentsArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};


export type ClientCountClientEditorVersionArgs = {
  where?: InputMaybe<ClientVersionWhereInput>;
};


export type ClientCountPenaltiesArgs = {
  where?: InputMaybe<PenaltyWhereInput>;
};


export type ClientCountWebAuthnDevicesArgs = {
  where?: InputMaybe<WebAuthnDeviceWhereInput>;
};

export type ClientListRelationFilter = {
  every?: InputMaybe<ClientWhereInput>;
  none?: InputMaybe<ClientWhereInput>;
  some?: InputMaybe<ClientWhereInput>;
};

export type ClientNullableRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export type ClientOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ClientOrderByWithRelationInput = {
  AppointmentVersion?: InputMaybe<AppointmentVersionOrderByRelationAggregateInput>;
  ClientVersion?: InputMaybe<ClientVersionOrderByRelationAggregateInput>;
  active?: InputMaybe<SortOrder>;
  appointments?: InputMaybe<AppointmentOrderByRelationAggregateInput>;
  blocked?: InputMaybe<SortOrder>;
  blockedReason?: InputMaybe<SortOrderInput>;
  clientEditorVersion?: InputMaybe<ClientVersionOrderByRelationAggregateInput>;
  favouriteBarber?: InputMaybe<BarberOrderByWithRelationInput>;
  favouriteBarberId?: InputMaybe<SortOrderInput>;
  favouriteService?: InputMaybe<ServiceOrderByWithRelationInput>;
  favouriteServiceId?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  penalties?: InputMaybe<PenaltyOrderByRelationAggregateInput>;
  phone?: InputMaybe<SortOrder>;
  referralCode?: InputMaybe<SortOrderInput>;
  referrerId?: InputMaybe<SortOrderInput>;
  smsNotification?: InputMaybe<SortOrder>;
  supertokensId?: InputMaybe<SortOrderInput>;
  tac?: InputMaybe<SortOrderInput>;
  userUID?: InputMaybe<SortOrderInput>;
  verified?: InputMaybe<SortOrder>;
  webAuthnDevices?: InputMaybe<WebAuthnDeviceOrderByRelationAggregateInput>;
};

export type ClientRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export enum ClientScalarFieldEnum {
  Active = 'active',
  Blocked = 'blocked',
  BlockedReason = 'blockedReason',
  FavouriteBarberId = 'favouriteBarberId',
  FavouriteServiceId = 'favouriteServiceId',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Name = 'name',
  Phone = 'phone',
  ReferralCode = 'referralCode',
  ReferrerId = 'referrerId',
  SmsNotification = 'smsNotification',
  SupertokensId = 'supertokensId',
  Tac = 'tac',
  UserUid = 'userUID',
  Verified = 'verified'
}

export type ClientService = {
  __typename?: 'ClientService';
  _count?: Maybe<ServiceCount>;
  barberServices?: Maybe<Array<ClientBarberService>>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type ClientVersion = {
  __typename?: 'ClientVersion';
  active?: Maybe<Scalars['Boolean']['output']>;
  barberEditor?: Maybe<Barber>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  blockedReason?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  clientEditor?: Maybe<Client>;
  favouriteBarberId?: Maybe<Scalars['Int']['output']>;
  favouriteServiceId?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referrerId?: Maybe<Scalars['Int']['output']>;
  smsNotification?: Maybe<Scalars['Boolean']['output']>;
  supertokensId?: Maybe<Scalars['String']['output']>;
  tac?: Maybe<Scalars['Boolean']['output']>;
  userUID?: Maybe<Scalars['String']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
  versionBarberEditorId?: Maybe<Scalars['Int']['output']>;
  versionClientEditorId?: Maybe<Scalars['Int']['output']>;
  versionClientId?: Maybe<Scalars['Int']['output']>;
  versionId: Scalars['Int']['output'];
  versionOperation: AuditOperation;
  versionTimestamp: Scalars['DateTimeISO']['output'];
};


export type ClientVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type ClientVersionClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type ClientVersionClientEditorArgs = {
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientVersionListRelationFilter = {
  every?: InputMaybe<ClientVersionWhereInput>;
  none?: InputMaybe<ClientVersionWhereInput>;
  some?: InputMaybe<ClientVersionWhereInput>;
};

export type ClientVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ClientVersionOrderByWithRelationInput = {
  active?: InputMaybe<SortOrderInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  blocked?: InputMaybe<SortOrderInput>;
  blockedReason?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  clientEditor?: InputMaybe<ClientOrderByWithRelationInput>;
  favouriteBarberId?: InputMaybe<SortOrderInput>;
  favouriteServiceId?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  referralCode?: InputMaybe<SortOrderInput>;
  referrerId?: InputMaybe<SortOrderInput>;
  smsNotification?: InputMaybe<SortOrderInput>;
  supertokensId?: InputMaybe<SortOrderInput>;
  tac?: InputMaybe<SortOrderInput>;
  userUID?: InputMaybe<SortOrderInput>;
  verified?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionClientEditorId?: InputMaybe<SortOrderInput>;
  versionClientId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum ClientVersionScalarFieldEnum {
  Active = 'active',
  Blocked = 'blocked',
  BlockedReason = 'blockedReason',
  FavouriteBarberId = 'favouriteBarberId',
  FavouriteServiceId = 'favouriteServiceId',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Name = 'name',
  Phone = 'phone',
  ReferralCode = 'referralCode',
  ReferrerId = 'referrerId',
  SmsNotification = 'smsNotification',
  SupertokensId = 'supertokensId',
  Tac = 'tac',
  UserUid = 'userUID',
  Verified = 'verified',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionClientEditorId = 'versionClientEditorId',
  VersionClientId = 'versionClientId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionTimestamp = 'versionTimestamp'
}

export type ClientVersionWhereInput = {
  AND?: InputMaybe<Array<ClientVersionWhereInput>>;
  NOT?: InputMaybe<Array<ClientVersionWhereInput>>;
  OR?: InputMaybe<Array<ClientVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  blocked?: InputMaybe<BoolNullableFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientEditor?: InputMaybe<ClientNullableRelationFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolNullableFilter>;
  supertokensId?: InputMaybe<StringNullableFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionClientId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ClientVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<ClientVersionWhereInput>>;
  NOT?: InputMaybe<Array<ClientVersionWhereInput>>;
  OR?: InputMaybe<Array<ClientVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  blocked?: InputMaybe<BoolNullableFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientEditor?: InputMaybe<ClientNullableRelationFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolNullableFilter>;
  supertokensId?: InputMaybe<StringNullableFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionClientEditorId?: InputMaybe<IntNullableFilter>;
  versionClientId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ClientWhereInput = {
  AND?: InputMaybe<Array<ClientWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  ClientVersion?: InputMaybe<ClientVersionListRelationFilter>;
  NOT?: InputMaybe<Array<ClientWhereInput>>;
  OR?: InputMaybe<Array<ClientWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  blocked?: InputMaybe<BoolFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  clientEditorVersion?: InputMaybe<ClientVersionListRelationFilter>;
  favouriteBarber?: InputMaybe<BarberNullableRelationFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteService?: InputMaybe<ServiceNullableRelationFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  penalties?: InputMaybe<PenaltyListRelationFilter>;
  phone?: InputMaybe<StringFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolFilter>;
  supertokensId?: InputMaybe<StringNullableFilter>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolFilter>;
  webAuthnDevices?: InputMaybe<WebAuthnDeviceListRelationFilter>;
};

export type ClientWhereUniqueInput = {
  AND?: InputMaybe<Array<ClientWhereInput>>;
  AppointmentVersion?: InputMaybe<AppointmentVersionListRelationFilter>;
  ClientVersion?: InputMaybe<ClientVersionListRelationFilter>;
  NOT?: InputMaybe<Array<ClientWhereInput>>;
  OR?: InputMaybe<Array<ClientWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  blocked?: InputMaybe<BoolFilter>;
  blockedReason?: InputMaybe<StringNullableFilter>;
  clientEditorVersion?: InputMaybe<ClientVersionListRelationFilter>;
  favouriteBarber?: InputMaybe<BarberNullableRelationFilter>;
  favouriteBarberId?: InputMaybe<IntNullableFilter>;
  favouriteService?: InputMaybe<ServiceNullableRelationFilter>;
  favouriteServiceId?: InputMaybe<IntNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  penalties?: InputMaybe<PenaltyListRelationFilter>;
  phone?: InputMaybe<Scalars['String']['input']>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referrerId?: InputMaybe<IntNullableFilter>;
  smsNotification?: InputMaybe<BoolFilter>;
  supertokensId?: InputMaybe<Scalars['String']['input']>;
  tac?: InputMaybe<BoolNullableFilter>;
  userUID?: InputMaybe<StringNullableFilter>;
  verified?: InputMaybe<BoolFilter>;
  webAuthnDevices?: InputMaybe<WebAuthnDeviceListRelationFilter>;
};

/** Input for completing WebAuthn authentication */
export type CompleteWebAuthnAuthenticationInput = {
  /** Phone number in E.164 format associated with the WebAuthn credential */
  phoneNumber: Scalars['String']['input'];
  /** WebAuthn authentication response containing assertion and client data */
  response: Scalars['JSON']['input'];
};

/** Input for completing WebAuthn device registration */
export type CompleteWebAuthnRegistrationInput = {
  /** WebAuthn registration response containing attestation and client data */
  response: Scalars['JSON']['input'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type EnumAuditOperationFilter = {
  equals?: InputMaybe<AuditOperation>;
  in?: InputMaybe<Array<AuditOperation>>;
  not?: InputMaybe<NestedEnumAuditOperationFilter>;
  notIn?: InputMaybe<Array<AuditOperation>>;
};

export type EnumBarberTypeFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type EnumBarberTypeNullableFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeNullableFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type IAvailableTimeSlot = {
  barber: ClientBarber;
  barberId: Scalars['Int']['output'];
  date: Scalars['DateTimeISO']['output'];
  service: ClientService;
  serviceId: Scalars['Int']['output'];
  timeSlots: Array<TimeSlot>;
};

export type ITimeSlot = {
  end: Scalars['DateTimeISO']['output'];
  start: Scalars['DateTimeISO']['output'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bookAppointment: ClientAppointment;
  cancelAppointment?: Maybe<ClientAppointment>;
  /** Completes WebAuthn authentication by verifying the credential and creating a session. */
  completeWebAuthnAuthentication: AuthResponse;
  /** Completes WebAuthn registration by verifying and storing the credential. */
  completeWebAuthnRegistration: AuthResponse;
  register: ClientClient;
  /**
   * Sends OTP code to the provided phone number.
   *     For registration, client must exist in database but not be verified.
   *     For login, client must be already verified.
   */
  sendOTP: OtpResponse;
  sendPhoneVerificationOTP: OtpResponse;
  updateProfile: ClientClient;
  /** Verifies OTP code and completes registration/login. For registration, requires name parameter. Creates SuperTokens session upon success. */
  verifyOTP: AuthResponse;
  verifyPhoneOTP: AuthResponse;
};


export type MutationBookAppointmentArgs = {
  data: BookAppointmentInput;
};


export type MutationCancelAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};


export type MutationCompleteWebAuthnAuthenticationArgs = {
  input: CompleteWebAuthnAuthenticationInput;
};


export type MutationCompleteWebAuthnRegistrationArgs = {
  input: CompleteWebAuthnRegistrationInput;
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationSendOtpArgs = {
  input: SendOtpInput;
};


export type MutationSendPhoneVerificationOtpArgs = {
  input: SendPhoneVerificationOtpInput;
};


export type MutationUpdateProfileArgs = {
  data: UpdateProfileInput;
};


export type MutationVerifyOtpArgs = {
  input: VerifyOtpInput;
};


export type MutationVerifyPhoneOtpArgs = {
  input: VerifyPhoneOtpInput;
};

export type NestedBigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBytesFilter = {
  equals?: InputMaybe<Scalars['Byte']['input']>;
  in?: InputMaybe<Array<Scalars['Byte']['input']>>;
  not?: InputMaybe<NestedBytesFilter>;
  notIn?: InputMaybe<Array<Scalars['Byte']['input']>>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedEnumAuditOperationFilter = {
  equals?: InputMaybe<AuditOperation>;
  in?: InputMaybe<Array<AuditOperation>>;
  not?: InputMaybe<NestedEnumAuditOperationFilter>;
  notIn?: InputMaybe<Array<AuditOperation>>;
};

export type NestedEnumBarberTypeFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type NestedEnumBarberTypeNullableFilter = {
  equals?: InputMaybe<BarberType>;
  in?: InputMaybe<Array<BarberType>>;
  not?: InputMaybe<NestedEnumBarberTypeNullableFilter>;
  notIn?: InputMaybe<Array<BarberType>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type OtpResponse = {
  __typename?: 'OTPResponse';
  deviceId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  preAuthSessionId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Penalty = {
  __typename?: 'Penalty';
  PenaltyVersion: Array<PenaltyVersion>;
  _count?: Maybe<PenaltyCount>;
  client: Client;
  clientId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
};


export type PenaltyPenaltyVersionArgs = {
  cursor?: InputMaybe<PenaltyVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PenaltyVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PenaltyVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PenaltyVersionWhereInput>;
};

export type PenaltyCount = {
  __typename?: 'PenaltyCount';
  PenaltyVersion: Scalars['Int']['output'];
};


export type PenaltyCountPenaltyVersionArgs = {
  where?: InputMaybe<PenaltyVersionWhereInput>;
};

export type PenaltyListRelationFilter = {
  every?: InputMaybe<PenaltyWhereInput>;
  none?: InputMaybe<PenaltyWhereInput>;
  some?: InputMaybe<PenaltyWhereInput>;
};

export type PenaltyNullableRelationFilter = {
  is?: InputMaybe<PenaltyWhereInput>;
  isNot?: InputMaybe<PenaltyWhereInput>;
};

export type PenaltyOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PenaltyOrderByWithRelationInput = {
  PenaltyVersion?: InputMaybe<PenaltyVersionOrderByRelationAggregateInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reason?: InputMaybe<SortOrder>;
};

export enum PenaltyScalarFieldEnum {
  ClientId = 'clientId',
  Id = 'id',
  Reason = 'reason'
}

export type PenaltyVersion = {
  __typename?: 'PenaltyVersion';
  barberEditor?: Maybe<Barber>;
  clientId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  penalty?: Maybe<Penalty>;
  reason?: Maybe<Scalars['String']['output']>;
  versionBarberEditorId?: Maybe<Scalars['Int']['output']>;
  versionId: Scalars['Int']['output'];
  versionOperation: AuditOperation;
  versionPenaltyId?: Maybe<Scalars['Int']['output']>;
  versionTimestamp: Scalars['DateTimeISO']['output'];
};


export type PenaltyVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type PenaltyVersionPenaltyArgs = {
  where?: InputMaybe<PenaltyWhereInput>;
};

export type PenaltyVersionListRelationFilter = {
  every?: InputMaybe<PenaltyVersionWhereInput>;
  none?: InputMaybe<PenaltyVersionWhereInput>;
  some?: InputMaybe<PenaltyVersionWhereInput>;
};

export type PenaltyVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PenaltyVersionOrderByWithRelationInput = {
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  penalty?: InputMaybe<PenaltyOrderByWithRelationInput>;
  reason?: InputMaybe<SortOrderInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionPenaltyId?: InputMaybe<SortOrderInput>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum PenaltyVersionScalarFieldEnum {
  ClientId = 'clientId',
  Id = 'id',
  Reason = 'reason',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionPenaltyId = 'versionPenaltyId',
  VersionTimestamp = 'versionTimestamp'
}

export type PenaltyVersionWhereInput = {
  AND?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  OR?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  penalty?: InputMaybe<PenaltyNullableRelationFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionPenaltyId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type PenaltyVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  OR?: InputMaybe<Array<PenaltyVersionWhereInput>>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  clientId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  penalty?: InputMaybe<PenaltyNullableRelationFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionPenaltyId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type PenaltyWhereInput = {
  AND?: InputMaybe<Array<PenaltyWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyWhereInput>>;
  OR?: InputMaybe<Array<PenaltyWhereInput>>;
  PenaltyVersion?: InputMaybe<PenaltyVersionListRelationFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  reason?: InputMaybe<StringFilter>;
};

export type PenaltyWhereUniqueInput = {
  AND?: InputMaybe<Array<PenaltyWhereInput>>;
  NOT?: InputMaybe<Array<PenaltyWhereInput>>;
  OR?: InputMaybe<Array<PenaltyWhereInput>>;
  PenaltyVersion?: InputMaybe<PenaltyVersionListRelationFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<StringFilter>;
};

export type Query = {
  __typename?: 'Query';
  appointments: Array<ClientAppointment>;
  availableTimeSlots: Array<AvailableTimeSlot>;
  barbers: Array<ClientBarber>;
  closestAvailableTimeSlots: Array<AvailableTimeSlot>;
  phoneExists: Scalars['Boolean']['output'];
  services: Array<ClientService>;
  /** Initiates WebAuthn authentication process. Client must have at least one registered WebAuthn device. */
  startWebAuthnAuthentication: WebAuthnAuthenticationResponse;
  /** Initiates WebAuthn registration process. User must be authenticated via OTP first. */
  startWebAuthnRegistration: WebAuthnRegistrationResponse;
  user?: Maybe<ClientClient>;
};


export type QueryAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ClientAppointmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientAppointmentWhereInput>;
};


export type QueryAvailableTimeSlotsArgs = {
  data: AvailableTimeSlotsInput;
};


export type QueryPhoneExistsArgs = {
  phone: Scalars['String']['input'];
};


export type QueryStartWebAuthnAuthenticationArgs = {
  phoneNumber: Scalars['String']['input'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RegisterInput = {
  /** The Firebase ID Token */
  idToken: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  refererCode?: InputMaybe<Scalars['String']['input']>;
  tac: Scalars['Boolean']['input'];
  userUID: Scalars['String']['input'];
};

/** Input for sending OTP code to a phone number */
export type SendOtpInput = {
  /** True if this is for new user registration, false for login */
  isRegistration: Scalars['Boolean']['input'];
  /** Phone number in E.164 format (e.g., +5511999999999) */
  phoneNumber: Scalars['String']['input'];
};

/** Input for sending OTP code to verify new phone number */
export type SendPhoneVerificationOtpInput = {
  newPhone: Scalars['String']['input'];
};

export type Service = {
  __typename?: 'Service';
  Client: Array<Client>;
  ServiceVersion: Array<ServiceVersion>;
  _count?: Maybe<ServiceCount>;
  active: Scalars['Boolean']['output'];
  appointments: Array<Appointment>;
  barberServices: Array<BarberService>;
  bookable: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  orderNo?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Int']['output'];
  priority?: Maybe<Scalars['Boolean']['output']>;
};


export type ServiceClientArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type ServiceServiceVersionArgs = {
  cursor?: InputMaybe<ServiceVersionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceVersionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceVersionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceVersionWhereInput>;
};


export type ServiceAppointmentsArgs = {
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AppointmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AppointmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppointmentWhereInput>;
};


export type ServiceBarberServicesArgs = {
  cursor?: InputMaybe<BarberServiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<BarberServiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BarberServiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BarberServiceWhereInput>;
};

export type ServiceCount = {
  __typename?: 'ServiceCount';
  Client: Scalars['Int']['output'];
  ServiceVersion: Scalars['Int']['output'];
  appointments: Scalars['Int']['output'];
  barberServices: Scalars['Int']['output'];
};


export type ServiceCountClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type ServiceCountServiceVersionArgs = {
  where?: InputMaybe<ServiceVersionWhereInput>;
};


export type ServiceCountAppointmentsArgs = {
  where?: InputMaybe<AppointmentWhereInput>;
};


export type ServiceCountBarberServicesArgs = {
  where?: InputMaybe<BarberServiceWhereInput>;
};

export type ServiceNullableRelationFilter = {
  is?: InputMaybe<ServiceWhereInput>;
  isNot?: InputMaybe<ServiceWhereInput>;
};

export type ServiceOrderByWithRelationInput = {
  Client?: InputMaybe<ClientOrderByRelationAggregateInput>;
  ServiceVersion?: InputMaybe<ServiceVersionOrderByRelationAggregateInput>;
  active?: InputMaybe<SortOrder>;
  appointments?: InputMaybe<AppointmentOrderByRelationAggregateInput>;
  barberServices?: InputMaybe<BarberServiceOrderByRelationAggregateInput>;
  bookable?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orderNo?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrder>;
  priority?: InputMaybe<SortOrderInput>;
};

export type ServiceRelationFilter = {
  is?: InputMaybe<ServiceWhereInput>;
  isNot?: InputMaybe<ServiceWhereInput>;
};

export type ServiceVersion = {
  __typename?: 'ServiceVersion';
  active?: Maybe<Scalars['Boolean']['output']>;
  barberEditor?: Maybe<Barber>;
  bookable?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orderNo?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  service?: Maybe<Service>;
  versionBarberEditorId?: Maybe<Scalars['Int']['output']>;
  versionId: Scalars['Int']['output'];
  versionOperation: AuditOperation;
  versionServiceId?: Maybe<Scalars['Int']['output']>;
  versionTimestamp: Scalars['DateTimeISO']['output'];
};


export type ServiceVersionBarberEditorArgs = {
  where?: InputMaybe<BarberWhereInput>;
};


export type ServiceVersionServiceArgs = {
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceVersionListRelationFilter = {
  every?: InputMaybe<ServiceVersionWhereInput>;
  none?: InputMaybe<ServiceVersionWhereInput>;
  some?: InputMaybe<ServiceVersionWhereInput>;
};

export type ServiceVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ServiceVersionOrderByWithRelationInput = {
  active?: InputMaybe<SortOrderInput>;
  barberEditor?: InputMaybe<BarberOrderByWithRelationInput>;
  bookable?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  duration?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  orderNo?: InputMaybe<SortOrderInput>;
  price?: InputMaybe<SortOrderInput>;
  priority?: InputMaybe<SortOrderInput>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  versionBarberEditorId?: InputMaybe<SortOrderInput>;
  versionId?: InputMaybe<SortOrder>;
  versionOperation?: InputMaybe<SortOrder>;
  versionServiceId?: InputMaybe<SortOrderInput>;
  versionTimestamp?: InputMaybe<SortOrder>;
};

export enum ServiceVersionScalarFieldEnum {
  Active = 'active',
  Bookable = 'bookable',
  Description = 'description',
  Duration = 'duration',
  Id = 'id',
  Name = 'name',
  OrderNo = 'orderNo',
  Price = 'price',
  Priority = 'priority',
  VersionBarberEditorId = 'versionBarberEditorId',
  VersionId = 'versionId',
  VersionOperation = 'versionOperation',
  VersionServiceId = 'versionServiceId',
  VersionTimestamp = 'versionTimestamp'
}

export type ServiceVersionWhereInput = {
  AND?: InputMaybe<Array<ServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<ServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<ServiceVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  bookable?: InputMaybe<BoolNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  orderNo?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  priority?: InputMaybe<BoolNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<IntFilter>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionServiceId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ServiceVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceVersionWhereInput>>;
  NOT?: InputMaybe<Array<ServiceVersionWhereInput>>;
  OR?: InputMaybe<Array<ServiceVersionWhereInput>>;
  active?: InputMaybe<BoolNullableFilter>;
  barberEditor?: InputMaybe<BarberNullableRelationFilter>;
  bookable?: InputMaybe<BoolNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  orderNo?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntNullableFilter>;
  priority?: InputMaybe<BoolNullableFilter>;
  service?: InputMaybe<ServiceNullableRelationFilter>;
  versionBarberEditorId?: InputMaybe<IntNullableFilter>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionOperation?: InputMaybe<EnumAuditOperationFilter>;
  versionServiceId?: InputMaybe<IntNullableFilter>;
  versionTimestamp?: InputMaybe<DateTimeFilter>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  Client?: InputMaybe<ClientListRelationFilter>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  ServiceVersion?: InputMaybe<ServiceVersionListRelationFilter>;
  active?: InputMaybe<BoolFilter>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  barberServices?: InputMaybe<BarberServiceListRelationFilter>;
  bookable?: InputMaybe<BoolFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  orderNo?: InputMaybe<IntNullableFilter>;
  price?: InputMaybe<IntFilter>;
  priority?: InputMaybe<BoolNullableFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TimeSlot = ITimeSlot & {
  __typename?: 'TimeSlot';
  end: Scalars['DateTimeISO']['output'];
  start: Scalars['DateTimeISO']['output'];
};

export type UpdateProfileInput = {
  favouriteBarberId?: InputMaybe<Scalars['Int']['input']>;
  favouriteServiceId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Input for verifying OTP code and completing authentication */
export type VerifyOtpInput = {
  /** Unique identifier for the device making the request */
  deviceId: Scalars['String']['input'];
  /** User's full name, required only during registration */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Phone number in E.164 format that received the OTP */
  phoneNumber: Scalars['String']['input'];
  /** Session ID received from the sendOTP mutation */
  preAuthSessionId: Scalars['String']['input'];
  /** OTP code entered by the user */
  userInputCode: Scalars['String']['input'];
};

/** Input for verifying OTP code for new phone number */
export type VerifyPhoneOtpInput = {
  deviceId: Scalars['String']['input'];
  newPhone: Scalars['String']['input'];
  preAuthSessionId: Scalars['String']['input'];
  userInputCode: Scalars['String']['input'];
};

export type WebAuthnAuthenticationResponse = {
  __typename?: 'WebAuthnAuthenticationResponse';
  options: Scalars['JSON']['output'];
  success: Scalars['Boolean']['output'];
};

export type WebAuthnDevice = {
  __typename?: 'WebAuthnDevice';
  backedUp: Scalars['Boolean']['output'];
  client: Client;
  clientId: Scalars['Int']['output'];
  counter: Scalars['BigInt']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  credentialID: Scalars['String']['output'];
  credentialPublicKey: Scalars['Byte']['output'];
  deviceName?: Maybe<Scalars['String']['output']>;
  deviceType: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastUsed: Scalars['DateTimeISO']['output'];
  transports: Array<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  webauthnUserID?: Maybe<Scalars['String']['output']>;
};

export type WebAuthnDeviceListRelationFilter = {
  every?: InputMaybe<WebAuthnDeviceWhereInput>;
  none?: InputMaybe<WebAuthnDeviceWhereInput>;
  some?: InputMaybe<WebAuthnDeviceWhereInput>;
};

export type WebAuthnDeviceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WebAuthnDeviceOrderByWithRelationInput = {
  backedUp?: InputMaybe<SortOrder>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrder>;
  counter?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  credentialID?: InputMaybe<SortOrder>;
  credentialPublicKey?: InputMaybe<SortOrder>;
  deviceName?: InputMaybe<SortOrderInput>;
  deviceType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastUsed?: InputMaybe<SortOrder>;
  transports?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  webauthnUserID?: InputMaybe<SortOrderInput>;
};

export enum WebAuthnDeviceScalarFieldEnum {
  BackedUp = 'backedUp',
  ClientId = 'clientId',
  Counter = 'counter',
  CreatedAt = 'createdAt',
  CredentialId = 'credentialID',
  CredentialPublicKey = 'credentialPublicKey',
  DeviceName = 'deviceName',
  DeviceType = 'deviceType',
  Id = 'id',
  LastUsed = 'lastUsed',
  Transports = 'transports',
  UpdatedAt = 'updatedAt',
  WebauthnUserId = 'webauthnUserID'
}

export type WebAuthnDeviceWhereInput = {
  AND?: InputMaybe<Array<WebAuthnDeviceWhereInput>>;
  NOT?: InputMaybe<Array<WebAuthnDeviceWhereInput>>;
  OR?: InputMaybe<Array<WebAuthnDeviceWhereInput>>;
  backedUp?: InputMaybe<BoolFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<IntFilter>;
  counter?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credentialID?: InputMaybe<StringFilter>;
  credentialPublicKey?: InputMaybe<BytesFilter>;
  deviceName?: InputMaybe<StringNullableFilter>;
  deviceType?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastUsed?: InputMaybe<DateTimeFilter>;
  transports?: InputMaybe<StringNullableListFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  webauthnUserID?: InputMaybe<StringNullableFilter>;
};

export type WebAuthnDeviceWhereUniqueInput = {
  AND?: InputMaybe<Array<WebAuthnDeviceWhereInput>>;
  NOT?: InputMaybe<Array<WebAuthnDeviceWhereInput>>;
  OR?: InputMaybe<Array<WebAuthnDeviceWhereInput>>;
  backedUp?: InputMaybe<BoolFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<IntFilter>;
  counter?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credentialID?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<BytesFilter>;
  deviceName?: InputMaybe<StringNullableFilter>;
  deviceType?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastUsed?: InputMaybe<DateTimeFilter>;
  transports?: InputMaybe<StringNullableListFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  webauthnUserID?: InputMaybe<StringNullableFilter>;
};

export type WebAuthnRegistrationResponse = {
  __typename?: 'WebAuthnRegistrationResponse';
  options: Scalars['JSON']['output'];
  success: Scalars['Boolean']['output'];
};

export type SendOtpMutationVariables = Exact<{
  input: SendOtpInput;
}>;


export type SendOtpMutation = { __typename?: 'Mutation', sendOTP: { __typename?: 'OTPResponse', success: boolean, message?: string | null, preAuthSessionId: string, deviceId: string } };

export type VerifyOtpMutationVariables = Exact<{
  input: VerifyOtpInput;
}>;


export type VerifyOtpMutation = { __typename?: 'Mutation', verifyOTP: { __typename?: 'AuthResponse', success: boolean, user?: { __typename?: 'Client', id: number, phone: string, firstName: string, lastName: string, name?: string | null, verified: boolean } | null } };

export type CompleteWebAuthnRegistrationMutationVariables = Exact<{
  input: CompleteWebAuthnRegistrationInput;
}>;


export type CompleteWebAuthnRegistrationMutation = { __typename?: 'Mutation', completeWebAuthnRegistration: { __typename?: 'AuthResponse', success: boolean } };

export type CompleteWebAuthnAuthenticationMutationVariables = Exact<{
  input: CompleteWebAuthnAuthenticationInput;
}>;


export type CompleteWebAuthnAuthenticationMutation = { __typename?: 'Mutation', completeWebAuthnAuthentication: { __typename?: 'AuthResponse', success: boolean } };

export type SendPhoneVerificationOtpMutationVariables = Exact<{
  input: SendPhoneVerificationOtpInput;
}>;


export type SendPhoneVerificationOtpMutation = { __typename?: 'Mutation', sendPhoneVerificationOTP: { __typename?: 'OTPResponse', success: boolean, message?: string | null, preAuthSessionId: string, deviceId: string } };

export type VerifyPhoneOtpMutationVariables = Exact<{
  input: VerifyPhoneOtpInput;
}>;


export type VerifyPhoneOtpMutation = { __typename?: 'Mutation', verifyPhoneOTP: { __typename?: 'AuthResponse', success: boolean, message?: string | null, user?: { __typename?: 'Client', id: number, phone: string, firstName: string, lastName: string, name?: string | null, verified: boolean } | null } };

export type BookAppointmentMutationVariables = Exact<{
  data: BookAppointmentInput;
}>;


export type BookAppointmentMutation = { __typename?: 'Mutation', bookAppointment: { __typename?: 'ClientAppointment', id: number } };

export type CancelAppointmentMutationVariables = Exact<{
  where: AppointmentWhereUniqueInput;
}>;


export type CancelAppointmentMutation = { __typename?: 'Mutation', cancelAppointment?: { __typename?: 'ClientAppointment', id: number } | null };

export type RegisterMutationVariables = Exact<{
  data: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'ClientClient', id: number, name: string, phone: string, verified: boolean, blocked: boolean } };

export type UpdateProfileMutationVariables = Exact<{
  data: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'ClientClient', id: number, name: string, phone: string, blocked: boolean, favouriteServiceId?: number | null, favouriteBarberId?: number | null, favouriteService?: { __typename?: 'ClientService', id: number, name: string, description?: string | null } | null, favouriteBarber?: { __typename?: 'ClientBarber', id: number, name: string } | null } };

export type AppointmentsQueryVariables = Exact<{
  where?: InputMaybe<ClientAppointmentWhereInput>;
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AppointmentsQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'ClientAppointment', id: number, start: any, end: any, barberId: number, serviceId: number, clientId: number, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }> };

export type StartWebAuthnRegistrationQueryVariables = Exact<{ [key: string]: never; }>;


export type StartWebAuthnRegistrationQuery = { __typename?: 'Query', startWebAuthnRegistration: { __typename?: 'WebAuthnRegistrationResponse', success: boolean, options: any } };

export type StartWebAuthnAuthenticationQueryVariables = Exact<{
  phoneNumber: Scalars['String']['input'];
}>;


export type StartWebAuthnAuthenticationQuery = { __typename?: 'Query', startWebAuthnAuthentication: { __typename?: 'WebAuthnAuthenticationResponse', success: boolean, options: any } };

export type AvailableTerminsQueryVariables = Exact<{
  data: AvailableTimeSlotsInput;
}>;


export type AvailableTerminsQuery = { __typename?: 'Query', availableTimeSlots: Array<{ __typename?: 'AvailableTimeSlot', date: any, barberId: number, serviceId: number, timeSlots: Array<{ __typename?: 'TimeSlot', start: any, end: any }> }> };

export type BarbersQueryVariables = Exact<{ [key: string]: never; }>;


export type BarbersQuery = { __typename?: 'Query', barbers: Array<{ __typename?: 'ClientBarber', id: number, name: string }> };

export type ClosestAvailableTerminsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClosestAvailableTerminsQuery = { __typename?: 'Query', closestAvailableTimeSlots: Array<{ __typename?: 'AvailableTimeSlot', date: any, barberId: number, serviceId: number, timeSlots: Array<{ __typename?: 'TimeSlot', start: any, end: any }>, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }> };

export type ServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type ServicesQuery = { __typename?: 'Query', services: Array<{ __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number, bookable?: boolean | null, barberServices?: Array<{ __typename?: 'ClientBarberService', id: number, barberId: number, barber: { __typename?: 'ClientBarber', id: number, name: string } }> | null }> };

export type MeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type MeDataQuery = { __typename?: 'Query', user?: { __typename?: 'ClientClient', id: number, name: string, phone: string, blocked: boolean, favouriteServiceId?: number | null, favouriteBarberId?: number | null, referralCode?: string | null, smsNotification?: boolean | null, favouriteService?: { __typename?: 'ClientService', id: number, name: string, description?: string | null } | null, favouriteBarber?: { __typename?: 'ClientBarber', id: number, name: string } | null } | null };

export type PhoneExistsQueryVariables = Exact<{
  phone: Scalars['String']['input'];
}>;


export type PhoneExistsQuery = { __typename?: 'Query', phoneExists: boolean };

export type HomeScreenQueryVariables = Exact<{
  date: Scalars['DateTimeISO']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type HomeScreenQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'ClientAppointment', id: number, start: any, end: any, barberId: number, serviceId: number, clientId: number, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }>, closestAvailableTimeSlots: Array<{ __typename?: 'AvailableTimeSlot', date: any, barberId: number, serviceId: number, timeSlots: Array<{ __typename?: 'TimeSlot', start: any, end: any }>, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }> };

export type TerminsScreenQueryVariables = Exact<{
  date: Scalars['DateTimeISO']['input'];
  cursor?: InputMaybe<AppointmentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TerminsScreenQuery = { __typename?: 'Query', futureAppointments: Array<{ __typename?: 'ClientAppointment', id: number, start: any, end: any, barberId: number, serviceId: number, clientId: number, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }>, pastAppointments: Array<{ __typename?: 'ClientAppointment', id: number, start: any, end: any, barberId: number, serviceId: number, clientId: number, barber: { __typename?: 'ClientBarber', id: number, name: string }, service: { __typename?: 'ClientService', id: number, name: string, description?: string | null, duration: number, price: number } }> };


export const SendOtpDocument = gql`
    mutation SendOTP($input: SendOTPInput!) {
  sendOTP(input: $input) {
    success
    message
    preAuthSessionId
    deviceId
  }
}
    `;
export type SendOtpMutationFn = Apollo.MutationFunction<SendOtpMutation, SendOtpMutationVariables>;

/**
 * __useSendOtpMutation__
 *
 * To run a mutation, you first call `useSendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOtpMutation, { data, loading, error }] = useSendOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOtpMutation(baseOptions?: Apollo.MutationHookOptions<SendOtpMutation, SendOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendOtpMutation, SendOtpMutationVariables>(SendOtpDocument, options);
      }
export type SendOtpMutationHookResult = ReturnType<typeof useSendOtpMutation>;
export type SendOtpMutationResult = Apollo.MutationResult<SendOtpMutation>;
export type SendOtpMutationOptions = Apollo.BaseMutationOptions<SendOtpMutation, SendOtpMutationVariables>;
export const VerifyOtpDocument = gql`
    mutation VerifyOTP($input: VerifyOTPInput!) {
  verifyOTP(input: $input) {
    success
    user {
      id
      phone
      firstName
      lastName
      name
      verified
    }
  }
}
    `;
export type VerifyOtpMutationFn = Apollo.MutationFunction<VerifyOtpMutation, VerifyOtpMutationVariables>;

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyOtpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOtpMutation, VerifyOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(VerifyOtpDocument, options);
      }
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>;
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>;
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const CompleteWebAuthnRegistrationDocument = gql`
    mutation CompleteWebAuthnRegistration($input: CompleteWebAuthnRegistrationInput!) {
  completeWebAuthnRegistration(input: $input) {
    success
  }
}
    `;
export type CompleteWebAuthnRegistrationMutationFn = Apollo.MutationFunction<CompleteWebAuthnRegistrationMutation, CompleteWebAuthnRegistrationMutationVariables>;

/**
 * __useCompleteWebAuthnRegistrationMutation__
 *
 * To run a mutation, you first call `useCompleteWebAuthnRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteWebAuthnRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeWebAuthnRegistrationMutation, { data, loading, error }] = useCompleteWebAuthnRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteWebAuthnRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CompleteWebAuthnRegistrationMutation, CompleteWebAuthnRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteWebAuthnRegistrationMutation, CompleteWebAuthnRegistrationMutationVariables>(CompleteWebAuthnRegistrationDocument, options);
      }
export type CompleteWebAuthnRegistrationMutationHookResult = ReturnType<typeof useCompleteWebAuthnRegistrationMutation>;
export type CompleteWebAuthnRegistrationMutationResult = Apollo.MutationResult<CompleteWebAuthnRegistrationMutation>;
export type CompleteWebAuthnRegistrationMutationOptions = Apollo.BaseMutationOptions<CompleteWebAuthnRegistrationMutation, CompleteWebAuthnRegistrationMutationVariables>;
export const CompleteWebAuthnAuthenticationDocument = gql`
    mutation CompleteWebAuthnAuthentication($input: CompleteWebAuthnAuthenticationInput!) {
  completeWebAuthnAuthentication(input: $input) {
    success
  }
}
    `;
export type CompleteWebAuthnAuthenticationMutationFn = Apollo.MutationFunction<CompleteWebAuthnAuthenticationMutation, CompleteWebAuthnAuthenticationMutationVariables>;

/**
 * __useCompleteWebAuthnAuthenticationMutation__
 *
 * To run a mutation, you first call `useCompleteWebAuthnAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteWebAuthnAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeWebAuthnAuthenticationMutation, { data, loading, error }] = useCompleteWebAuthnAuthenticationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteWebAuthnAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<CompleteWebAuthnAuthenticationMutation, CompleteWebAuthnAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteWebAuthnAuthenticationMutation, CompleteWebAuthnAuthenticationMutationVariables>(CompleteWebAuthnAuthenticationDocument, options);
      }
export type CompleteWebAuthnAuthenticationMutationHookResult = ReturnType<typeof useCompleteWebAuthnAuthenticationMutation>;
export type CompleteWebAuthnAuthenticationMutationResult = Apollo.MutationResult<CompleteWebAuthnAuthenticationMutation>;
export type CompleteWebAuthnAuthenticationMutationOptions = Apollo.BaseMutationOptions<CompleteWebAuthnAuthenticationMutation, CompleteWebAuthnAuthenticationMutationVariables>;
export const SendPhoneVerificationOtpDocument = gql`
    mutation SendPhoneVerificationOTP($input: SendPhoneVerificationOTPInput!) {
  sendPhoneVerificationOTP(input: $input) {
    success
    message
    preAuthSessionId
    deviceId
  }
}
    `;
export type SendPhoneVerificationOtpMutationFn = Apollo.MutationFunction<SendPhoneVerificationOtpMutation, SendPhoneVerificationOtpMutationVariables>;

/**
 * __useSendPhoneVerificationOtpMutation__
 *
 * To run a mutation, you first call `useSendPhoneVerificationOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPhoneVerificationOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPhoneVerificationOtpMutation, { data, loading, error }] = useSendPhoneVerificationOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPhoneVerificationOtpMutation(baseOptions?: Apollo.MutationHookOptions<SendPhoneVerificationOtpMutation, SendPhoneVerificationOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPhoneVerificationOtpMutation, SendPhoneVerificationOtpMutationVariables>(SendPhoneVerificationOtpDocument, options);
      }
export type SendPhoneVerificationOtpMutationHookResult = ReturnType<typeof useSendPhoneVerificationOtpMutation>;
export type SendPhoneVerificationOtpMutationResult = Apollo.MutationResult<SendPhoneVerificationOtpMutation>;
export type SendPhoneVerificationOtpMutationOptions = Apollo.BaseMutationOptions<SendPhoneVerificationOtpMutation, SendPhoneVerificationOtpMutationVariables>;
export const VerifyPhoneOtpDocument = gql`
    mutation VerifyPhoneOTP($input: VerifyPhoneOTPInput!) {
  verifyPhoneOTP(input: $input) {
    success
    message
    user {
      id
      phone
      firstName
      lastName
      name
      verified
    }
  }
}
    `;
export type VerifyPhoneOtpMutationFn = Apollo.MutationFunction<VerifyPhoneOtpMutation, VerifyPhoneOtpMutationVariables>;

/**
 * __useVerifyPhoneOtpMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneOtpMutation, { data, loading, error }] = useVerifyPhoneOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyPhoneOtpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPhoneOtpMutation, VerifyPhoneOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPhoneOtpMutation, VerifyPhoneOtpMutationVariables>(VerifyPhoneOtpDocument, options);
      }
export type VerifyPhoneOtpMutationHookResult = ReturnType<typeof useVerifyPhoneOtpMutation>;
export type VerifyPhoneOtpMutationResult = Apollo.MutationResult<VerifyPhoneOtpMutation>;
export type VerifyPhoneOtpMutationOptions = Apollo.BaseMutationOptions<VerifyPhoneOtpMutation, VerifyPhoneOtpMutationVariables>;
export const BookAppointmentDocument = gql`
    mutation bookAppointment($data: BookAppointmentInput!) {
  bookAppointment(data: $data) {
    id
  }
}
    `;
export type BookAppointmentMutationFn = Apollo.MutationFunction<BookAppointmentMutation, BookAppointmentMutationVariables>;

/**
 * __useBookAppointmentMutation__
 *
 * To run a mutation, you first call `useBookAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAppointmentMutation, { data, loading, error }] = useBookAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBookAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<BookAppointmentMutation, BookAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookAppointmentMutation, BookAppointmentMutationVariables>(BookAppointmentDocument, options);
      }
export type BookAppointmentMutationHookResult = ReturnType<typeof useBookAppointmentMutation>;
export type BookAppointmentMutationResult = Apollo.MutationResult<BookAppointmentMutation>;
export type BookAppointmentMutationOptions = Apollo.BaseMutationOptions<BookAppointmentMutation, BookAppointmentMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation cancelAppointment($where: AppointmentWhereUniqueInput!) {
  cancelAppointment(where: $where) {
    id
  }
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, options);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const RegisterDocument = gql`
    mutation register($data: RegisterInput!) {
  register(data: $data) {
    id
    name
    phone
    verified
    blocked
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($data: UpdateProfileInput!) {
  updateProfile(data: $data) {
    id
    name
    phone
    blocked
    favouriteService {
      id
      name
      description
    }
    favouriteBarber {
      id
      name
    }
    favouriteServiceId
    favouriteBarberId
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const AppointmentsDocument = gql`
    query Appointments($where: ClientAppointmentWhereInput, $cursor: AppointmentWhereUniqueInput, $take: Int, $skip: Int) {
  appointments(where: $where, cursor: $cursor, take: $take, skip: $skip) {
    id
    start
    end
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
    clientId
  }
}
    `;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
      }
export function useAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
        }
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<AppointmentsQuery, AppointmentsQueryVariables>;
export const StartWebAuthnRegistrationDocument = gql`
    query StartWebAuthnRegistration {
  startWebAuthnRegistration {
    success
    options
  }
}
    `;

/**
 * __useStartWebAuthnRegistrationQuery__
 *
 * To run a query within a React component, call `useStartWebAuthnRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartWebAuthnRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartWebAuthnRegistrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useStartWebAuthnRegistrationQuery(baseOptions?: Apollo.QueryHookOptions<StartWebAuthnRegistrationQuery, StartWebAuthnRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StartWebAuthnRegistrationQuery, StartWebAuthnRegistrationQueryVariables>(StartWebAuthnRegistrationDocument, options);
      }
export function useStartWebAuthnRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StartWebAuthnRegistrationQuery, StartWebAuthnRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StartWebAuthnRegistrationQuery, StartWebAuthnRegistrationQueryVariables>(StartWebAuthnRegistrationDocument, options);
        }
export type StartWebAuthnRegistrationQueryHookResult = ReturnType<typeof useStartWebAuthnRegistrationQuery>;
export type StartWebAuthnRegistrationLazyQueryHookResult = ReturnType<typeof useStartWebAuthnRegistrationLazyQuery>;
export type StartWebAuthnRegistrationQueryResult = Apollo.QueryResult<StartWebAuthnRegistrationQuery, StartWebAuthnRegistrationQueryVariables>;
export const StartWebAuthnAuthenticationDocument = gql`
    query StartWebAuthnAuthentication($phoneNumber: String!) {
  startWebAuthnAuthentication(phoneNumber: $phoneNumber) {
    success
    options
  }
}
    `;

/**
 * __useStartWebAuthnAuthenticationQuery__
 *
 * To run a query within a React component, call `useStartWebAuthnAuthenticationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartWebAuthnAuthenticationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartWebAuthnAuthenticationQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useStartWebAuthnAuthenticationQuery(baseOptions: Apollo.QueryHookOptions<StartWebAuthnAuthenticationQuery, StartWebAuthnAuthenticationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StartWebAuthnAuthenticationQuery, StartWebAuthnAuthenticationQueryVariables>(StartWebAuthnAuthenticationDocument, options);
      }
export function useStartWebAuthnAuthenticationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StartWebAuthnAuthenticationQuery, StartWebAuthnAuthenticationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StartWebAuthnAuthenticationQuery, StartWebAuthnAuthenticationQueryVariables>(StartWebAuthnAuthenticationDocument, options);
        }
export type StartWebAuthnAuthenticationQueryHookResult = ReturnType<typeof useStartWebAuthnAuthenticationQuery>;
export type StartWebAuthnAuthenticationLazyQueryHookResult = ReturnType<typeof useStartWebAuthnAuthenticationLazyQuery>;
export type StartWebAuthnAuthenticationQueryResult = Apollo.QueryResult<StartWebAuthnAuthenticationQuery, StartWebAuthnAuthenticationQueryVariables>;
export const AvailableTerminsDocument = gql`
    query AvailableTermins($data: AvailableTimeSlotsInput!) {
  availableTimeSlots(data: $data) {
    date
    timeSlots {
      start
      end
    }
    barberId
    serviceId
  }
}
    `;

/**
 * __useAvailableTerminsQuery__
 *
 * To run a query within a React component, call `useAvailableTerminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTerminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTerminsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAvailableTerminsQuery(baseOptions: Apollo.QueryHookOptions<AvailableTerminsQuery, AvailableTerminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableTerminsQuery, AvailableTerminsQueryVariables>(AvailableTerminsDocument, options);
      }
export function useAvailableTerminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableTerminsQuery, AvailableTerminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableTerminsQuery, AvailableTerminsQueryVariables>(AvailableTerminsDocument, options);
        }
export type AvailableTerminsQueryHookResult = ReturnType<typeof useAvailableTerminsQuery>;
export type AvailableTerminsLazyQueryHookResult = ReturnType<typeof useAvailableTerminsLazyQuery>;
export type AvailableTerminsQueryResult = Apollo.QueryResult<AvailableTerminsQuery, AvailableTerminsQueryVariables>;
export const BarbersDocument = gql`
    query Barbers {
  barbers {
    id
    name
  }
}
    `;

/**
 * __useBarbersQuery__
 *
 * To run a query within a React component, call `useBarbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBarbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBarbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBarbersQuery(baseOptions?: Apollo.QueryHookOptions<BarbersQuery, BarbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BarbersQuery, BarbersQueryVariables>(BarbersDocument, options);
      }
export function useBarbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BarbersQuery, BarbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BarbersQuery, BarbersQueryVariables>(BarbersDocument, options);
        }
export type BarbersQueryHookResult = ReturnType<typeof useBarbersQuery>;
export type BarbersLazyQueryHookResult = ReturnType<typeof useBarbersLazyQuery>;
export type BarbersQueryResult = Apollo.QueryResult<BarbersQuery, BarbersQueryVariables>;
export const ClosestAvailableTerminsDocument = gql`
    query closestAvailableTermins {
  closestAvailableTimeSlots {
    date
    timeSlots {
      start
      end
    }
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
  }
}
    `;

/**
 * __useClosestAvailableTerminsQuery__
 *
 * To run a query within a React component, call `useClosestAvailableTerminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosestAvailableTerminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosestAvailableTerminsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClosestAvailableTerminsQuery(baseOptions?: Apollo.QueryHookOptions<ClosestAvailableTerminsQuery, ClosestAvailableTerminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClosestAvailableTerminsQuery, ClosestAvailableTerminsQueryVariables>(ClosestAvailableTerminsDocument, options);
      }
export function useClosestAvailableTerminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClosestAvailableTerminsQuery, ClosestAvailableTerminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClosestAvailableTerminsQuery, ClosestAvailableTerminsQueryVariables>(ClosestAvailableTerminsDocument, options);
        }
export type ClosestAvailableTerminsQueryHookResult = ReturnType<typeof useClosestAvailableTerminsQuery>;
export type ClosestAvailableTerminsLazyQueryHookResult = ReturnType<typeof useClosestAvailableTerminsLazyQuery>;
export type ClosestAvailableTerminsQueryResult = Apollo.QueryResult<ClosestAvailableTerminsQuery, ClosestAvailableTerminsQueryVariables>;
export const ServicesDocument = gql`
    query Services {
  services {
    id
    name
    description
    duration
    price
    bookable
    barberServices {
      id
      barberId
      barber {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const MeDataDocument = gql`
    query MeData {
  user {
    id
    name
    phone
    blocked
    favouriteService {
      id
      name
      description
    }
    favouriteBarber {
      id
      name
    }
    favouriteServiceId
    favouriteBarberId
    referralCode
    smsNotification
  }
}
    `;

/**
 * __useMeDataQuery__
 *
 * To run a query within a React component, call `useMeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeDataQuery(baseOptions?: Apollo.QueryHookOptions<MeDataQuery, MeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeDataQuery, MeDataQueryVariables>(MeDataDocument, options);
      }
export function useMeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeDataQuery, MeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeDataQuery, MeDataQueryVariables>(MeDataDocument, options);
        }
export type MeDataQueryHookResult = ReturnType<typeof useMeDataQuery>;
export type MeDataLazyQueryHookResult = ReturnType<typeof useMeDataLazyQuery>;
export type MeDataQueryResult = Apollo.QueryResult<MeDataQuery, MeDataQueryVariables>;
export const PhoneExistsDocument = gql`
    query PhoneExists($phone: String!) {
  phoneExists(phone: $phone)
}
    `;

/**
 * __usePhoneExistsQuery__
 *
 * To run a query within a React component, call `usePhoneExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneExistsQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function usePhoneExistsQuery(baseOptions: Apollo.QueryHookOptions<PhoneExistsQuery, PhoneExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PhoneExistsQuery, PhoneExistsQueryVariables>(PhoneExistsDocument, options);
      }
export function usePhoneExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhoneExistsQuery, PhoneExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PhoneExistsQuery, PhoneExistsQueryVariables>(PhoneExistsDocument, options);
        }
export type PhoneExistsQueryHookResult = ReturnType<typeof usePhoneExistsQuery>;
export type PhoneExistsLazyQueryHookResult = ReturnType<typeof usePhoneExistsLazyQuery>;
export type PhoneExistsQueryResult = Apollo.QueryResult<PhoneExistsQuery, PhoneExistsQueryVariables>;
export const HomeScreenDocument = gql`
    query homeScreen($date: DateTimeISO!, $take: Int) {
  appointments(where: {start: {gt: $date}}, orderBy: {start: asc}, take: $take) {
    id
    start
    end
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
    clientId
  }
  closestAvailableTimeSlots {
    date
    timeSlots {
      start
      end
    }
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
  }
}
    `;

/**
 * __useHomeScreenQuery__
 *
 * To run a query within a React component, call `useHomeScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeScreenQuery({
 *   variables: {
 *      date: // value for 'date'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useHomeScreenQuery(baseOptions: Apollo.QueryHookOptions<HomeScreenQuery, HomeScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeScreenQuery, HomeScreenQueryVariables>(HomeScreenDocument, options);
      }
export function useHomeScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeScreenQuery, HomeScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeScreenQuery, HomeScreenQueryVariables>(HomeScreenDocument, options);
        }
export type HomeScreenQueryHookResult = ReturnType<typeof useHomeScreenQuery>;
export type HomeScreenLazyQueryHookResult = ReturnType<typeof useHomeScreenLazyQuery>;
export type HomeScreenQueryResult = Apollo.QueryResult<HomeScreenQuery, HomeScreenQueryVariables>;
export const TerminsScreenDocument = gql`
    query terminsScreen($date: DateTimeISO!, $cursor: AppointmentWhereUniqueInput, $take: Int, $skip: Int) {
  futureAppointments: appointments(
    where: {start: {gt: $date}}
    orderBy: {start: asc}
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    id
    start
    end
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
    clientId
  }
  pastAppointments: appointments(
    where: {start: {lte: $date}}
    orderBy: {start: desc}
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    id
    start
    end
    barberId
    barber {
      id
      name
    }
    serviceId
    service {
      id
      name
      description
      duration
      price
    }
    clientId
  }
}
    `;

/**
 * __useTerminsScreenQuery__
 *
 * To run a query within a React component, call `useTerminsScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminsScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminsScreenQuery({
 *   variables: {
 *      date: // value for 'date'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useTerminsScreenQuery(baseOptions: Apollo.QueryHookOptions<TerminsScreenQuery, TerminsScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TerminsScreenQuery, TerminsScreenQueryVariables>(TerminsScreenDocument, options);
      }
export function useTerminsScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerminsScreenQuery, TerminsScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TerminsScreenQuery, TerminsScreenQueryVariables>(TerminsScreenDocument, options);
        }
export type TerminsScreenQueryHookResult = ReturnType<typeof useTerminsScreenQuery>;
export type TerminsScreenLazyQueryHookResult = ReturnType<typeof useTerminsScreenLazyQuery>;
export type TerminsScreenQueryResult = Apollo.QueryResult<TerminsScreenQuery, TerminsScreenQueryVariables>;