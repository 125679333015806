import { createContext, useContext } from 'react';
import { Client, MeDataQuery } from '~/graphql/generated';

export const AuthContext = createContext<{
  user: Client | null;
  mounting: boolean;
  isLoggedIn: boolean;
  logoutUser: () => void;
  userData: MeDataQuery['user'] | undefined;
}>({
  isLoggedIn: false,
  logoutUser: () => {
    //
  },
  mounting: true,
  user: null,
  userData: undefined,
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
