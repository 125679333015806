import { ButtonProps, FormHelperText, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';

import { TypographyOptions } from '@mui/material/styles/createTypography';
import { styled } from '@mui/system';
import { OtpTimingData } from '~/utils/otp';
import { AppButton } from '../AppButton';

const StyledInput = styled('input')(
  ({ theme }) => `
  width: 3rem !important;
  height: 3rem;
  margin: 0;
  font-size: 1.4rem;
  border-radius: ${theme.spacing(1)};
  color: #000;
  border: 0;
  font-family: ${(theme.typography as TypographyOptions).fontFamily};
  font-weight: bold;

  &:focus {
    outline: none;
    border-color: red;
    border: 2px solid ${theme.palette.primary.main};
  }
`,
);

export interface IOtpCodeProps {
  onSubmit: (otp: string) => void;
  onResend: () => void;
  phone: string;
  buttonProps?: ButtonProps;
  error?: string;
  loading?: boolean;
  timingData: OtpTimingData | null;
}

export const OtpCode: FC<IOtpCodeProps> = (props) => {
  const [code, setCode] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  // Initialize countdown based on localStorage data
  useEffect(() => {
    const timingData = props.timingData;
    if (timingData) {
      const elapsedTime = Date.now() - timingData.timestamp;
      const remainingTime = Math.ceil((60 * 1000 - elapsedTime) / 1000);

      if (remainingTime > 0) {
        setCountdown(remainingTime);
        setIsResendDisabled(true);
      }
    }
  }, [props.phone, props.timingData]);

  // Countdown timer effect
  useEffect(() => {
    if (countdown > 0) {
      if (!isResendDisabled) {
        setIsResendDisabled(true);
      }
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsResendDisabled(false);
    }
  }, [countdown, isResendDisabled]);

  const handleResend = () => {
    if (!isResendDisabled) {
      setCountdown(60);
      setIsResendDisabled(true);
      props.onResend();
    }
  };

  const submit: ButtonProps['onClick'] = () => {
    props.onSubmit(code);
  };

  return (
    <Stack alignItems={'center'} justifyContent={'space-between'} flex={1} width={'100%'}>
      <Stack alignItems={'center'} mb={2} width={'100%'}>
        <Stack>
          <OtpInput
            numInputs={6}
            value={code}
            onChange={setCode}
            renderInput={(props) => <StyledInput {...props} />}
            containerStyle={{
              gap: 5,
              width: '100%',
              justifyContent: 'space-between',
            }}
          />
          {props.error && (
            <FormHelperText
              sx={{
                color: '#E58A8A',
                marginX: 1.75,
                mt: 0,
              }}
            >
              {props.error}
            </FormHelperText>
          )}
        </Stack>

        <Stack alignItems={'center'} my={3}>
          <Typography variant="body1">Poslan je OTP kod na vaš broj</Typography>
          <Typography variant="body1">{props.phone}</Typography>
        </Stack>

        <Stack alignItems={'center'} mt={5}>
          <Typography variant="body1">Niste primili kod?</Typography>
          <Typography
            onClick={handleResend}
            variant="body1"
            fontWeight={500}
            sx={{
              color: isResendDisabled ? 'text.disabled' : 'primary.main',
              cursor: isResendDisabled ? 'default' : 'pointer',
            }}
          >
            {isResendDisabled ? `Pošalji ponovno (${countdown}s)` : 'Pošalji ponovno'}
          </Typography>
        </Stack>
      </Stack>

      <AppButton
        loading={props.loading}
        variant="contained"
        disabled={code.length !== 6}
        fullWidth
        onClick={submit}
        {...props.buttonProps}
      >
        verificiraj
      </AppButton>
    </Stack>
  );
};
