import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import { Container, Link, Stack, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppMenuItem } from '~/components/AppMenuItem/AppMenuItem';
import { AuthContext } from '~/contexts/authContextCore';

export const Settings: FC = () => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  return (
    <Container
      sx={{
        flex: 1,
      }}
    >
      <Stack
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={3.25}
        width={'100%'}
        height={'100%'}
      >
        <Stack flexDirection={'column'} alignItems={'center'} gap={1.25} width={'100%'}>
          <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={1.25} pt={3.75} pb={2.5}>
            <Typography variant="h6" fontWeight={600}>
              {context.userData?.name}
            </Typography>
            <Typography fontWeight={500} fontSize={15}>
              {context.userData?.phone}
            </Typography>
          </Stack>
          <AppMenuItem text="Profil" icon={AccountCircleIcon} onClick={() => navigate('profile')} />
          <AppMenuItem text="Promijeni Broj" icon={LocalPhoneRoundedIcon} onClick={() => navigate('phone')} />
          <AppMenuItem
            text="Omiljena Usluga"
            icon={FavoriteRoundedIcon}
            onClick={() => navigate('favourite/service')}
          />
          <AppMenuItem text="Omiljeni Frizer" icon={FavoriteRoundedIcon} onClick={() => navigate('favourite/barber')} />
          <AppMenuItem
            text="Notifikacije"
            icon={NotificationsActiveRoundedIcon}
            onClick={() => navigate('notification')}
          />

          <Link
            href={`mailto:zlatnicesalj@yahoo.com?subject=Komentar na aplikaciju`}
            style={{
              textDecoration: 'none',
              width: '100%',
              color: 'white',
            }}
          >
            <AppMenuItem
              text="Ostavite Komentar"
              icon={DriveFileRenameOutlineRoundedIcon}
              onClick={() => navigate('/settings')}
            />
          </Link>

          <Link
            href={'https://www.facebook.com/profile.php?id=100090394613086'}
            target="_blank"
            rel="noopener"
            style={{
              textDecoration: 'none',
              width: '100%',
              color: 'white',
            }}
          >
            <AppMenuItem
              text="Podaci o Salonu"
              icon={InfoRoundedIcon}
              onClick={() => {
                //
              }}
            />
          </Link>
          <AppMenuItem text="Odjava" icon={LogoutRoundedIcon} onClick={() => context?.logoutUser()} />
        </Stack>

        <Stack alignItems={'center'}>
          <Typography color="info.main" fontSize={12} fontWeight={500}>
            Verzija {import.meta.env.VITE_APP_VERSION}
          </Typography>
          <Typography color="info.main" fontSize={12} fontWeight={500}>
            Copyright © 2024{' '}
            <Link target="_blank" href="https://www.linkedin.com/in/darko-pranji%C4%87-b221335a/">
              Codegree d.o.o.
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};
