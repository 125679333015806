import { Alert, Container, Stack, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { startRegistration } from '@simplewebauthn/browser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppButton } from '~/components/AppButton';
import { AppScreenInfo } from '~/components/AppScreenInfo/AppScreenInfo';
import { useAuth } from '~/contexts/authContextCore';
import { useCompleteWebAuthnRegistrationMutation, useStartWebAuthnRegistrationLazyQuery } from '~/graphql/generated';
import { checkWebAuthnSupport } from '~/utils/webauthn-support';

export default function SetupWebAuthn() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const webAuthnSupport = checkWebAuthnSupport();

  const [initRegistration] = useStartWebAuthnRegistrationLazyQuery();
  const [completeRegistration] = useCompleteWebAuthnRegistrationMutation();

  useEffect(() => {
    // If WebAuthn is not supported, redirect to home
    if (!webAuthnSupport.isSupported) {
      navigate('/', { replace: true });
    }
  }, [webAuthnSupport.isSupported, navigate]);

  const handleSetupWebAuthn = async () => {
    if (!user?.phone) {
      setError('Phone number not found');
      return;
    }

    setIsLoading(true);
    setError(undefined);

    try {
      // Start WebAuthn registration
      const { data } = await initRegistration();
      const options = data?.startWebAuthnRegistration.options;

      if (!options) {
        throw new Error('Failed to start registration');
      }

      // Create credentials
      const registrationJSON = await startRegistration({
        optionsJSON: options,
      });

      if (!registrationJSON) {
        throw new Error('Failed to create credentials');
      }

      // Complete registration
      const { data: completeData } = await completeRegistration({
        variables: {
          input: {
            response: registrationJSON,
          },
        },
      });

      if (!completeData?.completeWebAuthnRegistration.success) {
        throw new Error('Failed to complete registration');
      }

      // Registration successful, redirect to home
      navigate('/', { replace: true });
    } catch (error) {
      setError(error instanceof Error ? error.message : 'WebAuthn setup failed');
    } finally {
      setIsLoading(false);
    }
  };

  if (!webAuthnSupport.isSupported) {
    return (
      <AppScreenInfo text="Vaš browser ili uređaj ne podržava WebAuthn. Molimo vas da koristite podržani preglednik ili nastavite sa SMS autentikacijom." />
    );
  }

  return (
    <Stack height="100vh" width="100%">
      <Stack flex={1} overflow={'auto'}>
        <Stack flex={1} pb={3} position={'relative'}>
          <Stack
            sx={{
              width: '100%',
            }}
            gap={4}
          >
            <Container sx={{ py: 3.5, height: '100%' }}>
              <Stack flex={1} justifyContent="space-between" height="100%">
                <Stack gap={1}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      mb: 2,
                    }}
                  >
                    Postavite biometričku autentikaciju
                  </Typography>

                  <Typography>Povećajte sigurnost vašeg računa postavljanjem biometričke autentikacije.</Typography>

                  <Typography>
                    Biometrička autentikacija vam omogućuje brzu i sigurnu prijavu koristeći otisak prsta ili
                    prepoznavanje lica. Vaši biometrijski podaci ostaju sigurno pohranjeni samo na vašem uređaju, baš
                    kao što ih koristite za otključavanje telefona.
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{
                      color: 'primary.main',
                      fontWeight: 500,
                      mt: 2,
                    }}
                  >
                    Prednosti biometričke autentikacije:
                  </Typography>

                  <List sx={{ mt: 1 }}>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Brža i jednostavnija prijava - nema potrebe za unosom SMS koda" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Povećana sigurnost - biometrijski podaci su jedinstveni za vas" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Privatnost - biometrijski podaci ostaju na vašem uređaju" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Praktičnost - prijava u nekoliko sekundi" />
                    </ListItem>
                  </List>

                  {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                      {error}
                    </Alert>
                  )}
                </Stack>

                <AppButton
                  variant="contained"
                  size="large"
                  onClick={handleSetupWebAuthn}
                  loading={isLoading}
                  disabled={isLoading}
                  sx={{ mt: 4 }}
                >
                  Postavite biometričku autentikaciju
                </AppButton>
              </Stack>
            </Container>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
