import { GraphQLError } from 'graphql';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorFormatter = (error: GraphQLError | Error | null | unknown) => {
  if (!error) return '';
  if (error instanceof GraphQLError) {
    return getBackendErrorsDescription(error);
  }
  if (error instanceof Error) {
    return error.message;
  }
  return 'Dogodila se pogreška!';
};

export function getBackendErrorsDescription(error: GraphQLError) {
  switch (error.extensions?.code) {
    case 'AppointmentNotAssignedToClient':
      return 'Termin nije dodijeljen klijentu';
    case 'AppointmentPassed':
      return 'Termin je prošao';
    case 'BarberDisabled':
      return 'Frizer je onemogućen';
    case 'BarberExists':
      return 'Frizer već postoji';
    case 'BarberNotExists':
      return 'Frizer ne postoji';
    case 'BarberNotLoggedIn':
      return 'Frizer nije prijavljen';
    case 'NotActive':
      return 'Nije aktivan';
    case 'PasswordsMissmatch':
      return 'Neslaganje lozinki';
    case 'RegistrationDataMissmatch':
      return 'Neslaganje podataka za registraciju';
    case 'ServiceNotAssignedToBarber':
      return 'Usluga nije dodijeljena frizeru';
    case 'ServiceNoDuration':
      return 'Odabrana usluga nema trajanje';
    case 'ServiceTimeSlotsMissmatch':
      return 'Neslaganje trajanja usluge s poslanim vremenskim rasponima';
    case 'TacValidation':
      return 'Uvjeti korištenja nisu valjani';
    case 'TimeSlotsNotAvailable':
      return 'Vremenski rasponi nisu dostupni';
    case 'UserBookingCollision':
      return 'Sudar rezervacija korisnika';
    case 'UserMaxAppointmentsExceeded':
      return `Nije moguće rezervirati novi termin. Dozvoljen broj aktivnih rezervacija je ${
        import.meta.env.VITE_USER_BOOKING_MAX_APPOINTMENTS
      }`;
    case 'UserNotFound':
      return 'Korisnik nije pronađen';
    case 'UserPhoneExists':
      return 'Korisnik s brojem telefona već postoji';
    case 'UserTokenMissing':
      return 'Nedostaje token korisnika';
    case 'ValidationFailed':
      return 'Neuspjela provjera valjanosti';
    case 'AccessDenied':
      return 'Pristup odbijen';
    case 'AccessTokenExpired':
      return 'Pristupni token istekao';
    case 'ErrorDuringExecution':
      return 'Greška tijekom izvođenja';
    case 'AccessForbidden':
      return 'Pristup zabranjen';
    case 'NotAuthorized':
      return 'Nije ovlašten';
    case 'ThirdPartyError':
      return 'Greška treće strane';
    case 'TokenExpired':
      return 'Token istekao';
    case 'TokenNotValid':
      return 'Nevaljan token';
    case 'UserRefreshTokenNotValid':
      return 'Refresh token nije valjan za korisnika';

    case 'OTPSendFailed':
      return 'Slanje OTP koda nije uspjelo';
    case 'OTPVerificationFailed':
      return 'Provjera OTP koda nije uspjela';
    case 'InvalidPhoneNumber':
      return 'Broj telefona nije registriran u našem sustavu';
    case 'InvalidRecaptcha':
      return 'Neispravan reCAPTCHA token';
    case 'Unauthorized':
      return 'Korisnik nije ovlašten za izvršavanje ove radnje';
    case 'WebAuthnRegistrationFailed':
      return 'Registracija WebAuthn uređaja nije uspjela';
    case 'WebAuthnVerificationFailed':
      return 'Provjera WebAuthn vjerodajnica nije uspjela';
    case 'WebAuthnChallengeExpired':
      return 'WebAuthn izazov je istekao';
    case 'WebAuthnDeviceNotFound':
      return 'WebAuthn uređaj nije pronađen';
    case 'WebAuthnNotRegistered':
      return 'Nema registriranog WebAuthn uređaja za ovog korisnika';
    case 'WebAuthnAuthenticationFailed':
      return 'WebAuthn autentifikacija nije uspjela';
    case 'NoWebAuthnDevice':
      return 'Nema registriranog WebAuthn uređaja za ovog korisnika';
    case 'AlreadyRegistered':
      return 'Ovaj broj telefona je već registriran';
    case 'NotRegistered':
      return 'Molimo registrirajte se prije pokušaja prijave';
    case 'NameRequired':
      return 'Ime je obavezno za registraciju';
    case 'InvalidLoginAttempt':
      return 'Neispravan pokušaj prijave. Ime ne smije biti navedeno tijekom prijave';

    default:
      return error.message;
  }
}
